import { api } from "../../App/services/api";
function getTokensData() {
    return api.get('/reports/tokens');
}
function getSubscribersData() {
    return api.get('/reports/subscribers');
}
function getCustomersData(perPage, sort, customer_success_id, plan_id, erp_id) {
    var uri = "/reports/customers?limit=".concat(perPage);
    if (sort) {
        uri += "&sort=".concat(sort);
    }
    if (customer_success_id) {
        uri += "&customer_success_id=".concat(customer_success_id);
    }
    if (plan_id) {
        uri += "&plan_id=".concat(plan_id);
    }
    if (erp_id) {
        uri += "&erp_id=".concat(erp_id);
    }
    return api.get(uri);
}
function searchReport(searchTerm, perPage) {
    var url = "/reports/customers/search/".concat(searchTerm, "?limit=").concat(perPage);
    return api.post(url);
}
function getContentSupplierProducts() {
    return api.get('/content_suppliers_products');
}
function paginate(url, customer_success_id, plan_id, erp_id) {
    if (customer_success_id) {
        url += "&customer_success_id=".concat(customer_success_id);
    }
    if (plan_id) {
        url += "&plan_id=".concat(plan_id);
    }
    if (erp_id) {
        url += "&erp_id=".concat(erp_id);
    }
    return api.get(url);
}
function exportCustomersReport(providers) {
    return api.post('/reports/customers/export', { providers: providers });
}
export default {
    getTokensData: getTokensData,
    getSubscribersData: getSubscribersData,
    getCustomersData: getCustomersData,
    exportCustomersReport: exportCustomersReport,
    getContentSupplierProducts: getContentSupplierProducts,
    searchReport: searchReport,
    paginate: paginate
};
