import { api } from "../../App/services/api";
function all(perPage, selected, service, hasError) {
    if (selected === void 0) { selected = []; }
    if (service === void 0) { service = 0; }
    if (hasError === void 0) { hasError = 0; }
    var uri = "/subscribers?per_page=".concat(perPage);
    uri = selected.length === 0 ?
        uri :
        uri + "&selected=".concat(selected.toString());
    uri = service === 0 ?
        uri :
        uri + "&service=".concat(service);
    uri = hasError === 0 ?
        uri :
        uri + "&has_error=".concat(hasError);
    return api.get(uri);
}
function paginate(url) {
    return api.get(url);
}
function find(document) {
    return api.get("/subscribers/".concat(document));
}
function store(newSubscriberData) {
    return api.post("/subscribers", newSubscriberData);
}
//TODO: Ajustar a tipagem do subscribersData
function storeMany(subscribersData) {
    return api.post('/subscribers/import/csv', subscribersData);
}
function exportMany() {
    return api.get('/subscribers/export');
}
function update(document, updateSubscriberData) {
    return api.put("/subscribers/".concat(document), updateSubscriberData);
}
function destroy(document) {
    return api.delete("/subscribers/".concat(document));
}
function destroyMany(subscribers) {
    return api.post('/subscribers/destroy_many', {
        documents: subscribers
    });
}
function provision(request) {
    return api.post("/provision/activate", request);
}
function desprovision(request) {
    return api.post("/provision/deactivate", request);
}
function reactivate(request) {
    return api.post("/provision/change", request);
}
function suspend(request) {
    return api.post("/provision/suspend", request);
}
function change(contentSupplierProduct, newContentSupplierProduct, document) {
    return api.post('/provision/change', {
        previous_product_id: contentSupplierProduct,
        product_id: newContentSupplierProduct,
        document: document
    });
}
function search(subscriberSearch, perPage, selected, service, hasError) {
    if (selected === void 0) { selected = []; }
    if (service === void 0) { service = 0; }
    if (hasError === void 0) { hasError = 0; }
    var uri = "/subscribers/search/".concat(subscriberSearch, "?per_page=").concat(perPage);
    uri = selected.length === 0 ?
        uri :
        uri + "&selected=".concat(selected.toString());
    uri = service === 0 ?
        uri :
        uri + "&service=".concat(service);
    uri = hasError === 0 ?
        uri :
        uri + "&has_error=".concat(hasError);
    return api.get(uri);
}
function fixError(payloadData) {
    return api.post('/provision/fix_error', payloadData);
}
function qtyError() {
    return api.get("/subscribers/qty_error");
}
// TODO: Adicionar método que retorna os ContentSuppliers de um Subscriber.
function getSubscriberContentSupply(subscriberId) {
    return api.get("/subscribers/content_suppliers/".concat(subscriberId));
}
function getSubscriberSpeed() {
    return api.get("/subscribers/internet_speeds");
}
function getSubscriberHistory(subscriberId) {
    return api.get("/subscribers/history/".concat(subscriberId));
}
function resendEmail(payload) {
    return api.post('/subscribers/resend_activation_email', payload);
}
export default {
    all: all,
    paginate: paginate,
    find: find,
    store: store,
    reactivate: reactivate,
    storeMany: storeMany,
    update: update,
    destroy: destroy,
    destroyMany: destroyMany,
    provision: provision,
    desprovision: desprovision,
    change: change,
    suspend: suspend,
    search: search,
    fixError: fixError,
    qtyError: qtyError,
    getSubscriberSpeed: getSubscriberSpeed,
    getSubscriberHistory: getSubscriberHistory,
    resendEmail: resendEmail,
    exportMany: exportMany
};
