var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Footer from '../../App/components/Footer';
import '../styles/first-access.css';
import { useNavigate } from "react-router-dom";
import passwordSucess from "../../App/assets/sucess-password.png";
function PasswordChangedSuccess() {
    var navigate = useNavigate();
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "container" }, { children: _jsx("div", __assign({ className: "box-message-passsword-sucess" }, { children: _jsxs("div", __assign({ className: " d-flex justify-content-center align-items-center flex-column text-center box-password-sucess", style: { width: "507px" } }, { children: [_jsx("img", { src: passwordSucess, alt: "Imagem de sucesso", className: "img-sucess" }), _jsx("h2", __assign({ className: "title-password-sucess" }, { children: "Senha cadastrada com sucesso!" })), _jsx("p", __assign({ className: "text-password-sucess" }, { children: "Tudo pronto! Agora basta clicar no bot\u00E3o abaixo para prosseguir para o painel principal da plataforma." })), _jsx("button", __assign({ className: "w-100 btn-super btn-back-login", onClick: function () { return navigate('/login'); } }, { children: "Prosseguir" }))] })) })) })), _jsx("div", __assign({ className: "footer-sendmailchangepassword" }, { children: _jsx(Footer, {}) }))] }));
}
export default PasswordChangedSuccess;
