var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import MemberHandbookWelcome from "../components/MemberHandbookWelcome";
import InformationForTheMember from "../components/PromotionalMaterials";
import "../styles/MemberHandbook.css";
import BenefitsOfTheMemberManual from "../components/BenefitsOfTheMemberManual";
import CreationAndApprovalOfArts from "../components/CreationAndApprovalOfArts";
import CustomerSuccessTeam from "../components/CustomerSuccessTeam";
import Footer from "../../App/components/Footer";
function MemberHandbook() {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "container-member-handbook" }, { children: [_jsx(MemberHandbookWelcome, {}), _jsx(InformationForTheMember, {}), _jsx(CreationAndApprovalOfArts, {}), _jsx(BenefitsOfTheMemberManual, {}), _jsx(CustomerSuccessTeam, {}), _jsx("div", __assign({ className: "footer-member-handbook" }, { children: _jsx(Footer, {}) }))] })) }));
}
export default MemberHandbook;
