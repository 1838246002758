import { api } from "../../App/services/api";
function all() {
    return api.get('/users');
}
function store(userData) {
    return api.post('/users', userData);
}
function update(userData) {
    return api.put("/users/".concat(userData.id), userData);
}
function updateProfile(userData) {
    return api.put("/users/profile/".concat(userData.id), userData);
}
function destroy(userId) {
    return api.delete('/users/' + "".concat(userId));
}
function show(userId) {
    return api.get('/users/' + "".concat(userId));
}
function getPlatformPermissions() {
    return api.get('/permissions/platform');
}
function getCustomerCampaingValidation(customerId) {
    return api.get("/awards/get_customer_valid_campaing/".concat(customerId));
}
export default {
    all: all,
    store: store,
    destroy: destroy,
    update: update,
    updateProfile: updateProfile,
    show: show,
    getPlatformPermissions: getPlatformPermissions,
    getCustomerCampaingValidation: getCustomerCampaingValidation
};
