var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./../styles/UploadingArts.css";
import AddIcon from '@mui/icons-material/Add';
import ModalToSendTheArt from '../components/ModalToSendTheArt';
import InfoIcon from '@mui/icons-material/Info';
import ArtFile from '../components/ArtFile';
import Title from '../../App/components/Title';
import MemberHandbookService from '../../MemberHandbook/services/MemberHandbookService';
import "./../styles/UploadingArts.css";
import "../../App/styles/GlobalStyle.css";
function UploadingArts() {
    var _a = useState(false), modalShow = _a[0], setModalShow = _a[1];
    var _b = useState(), materialLinkURL = _b[0], setMaterialLinkURL = _b[1];
    var _c = useState(), materialPasswordURL = _c[0], setMaterialPasswordURL = _c[1];
    function upload() {
        setModalShow(true);
    }
    useEffect(function () {
        function getTheMaterialLink() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, MemberHandbookService.memberHandbookURL().then(function (res) {
                                setMaterialLinkURL(res.data.arts_url);
                                setMaterialPasswordURL(res.data.arts_url_password);
                            })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        getTheMaterialLink();
    }, []);
    return (_jsxs("div", __assign({ className: "container-uploading-arts" }, { children: [_jsx(Title, { text: "Upload" }), _jsxs("div", __assign({ className: "warning-box-arts" }, { children: [_jsx(InfoIcon, { className: "info-icon-art" }), " Envie suas artes para aprova\u00E7\u00E3o. Voc\u00EA receber\u00E1 uma resposta por email dentro de at\u00E9 7 dias \u00FAteis."] })), _jsxs("div", __assign({ className: "card-download-materials" }, { children: [_jsxs("div", { children: [_jsx(InfoIcon, { className: "info-icon-art" }), "Para ter acesso aos materiais fornecidos pelos nossos parceiros, acesse o link em \u201Cbaixar materiais\u201D."] }), _jsx("div", { children: materialLinkURL ? (_jsx(_Fragment, { children: _jsx(Link, __assign({ to: materialLinkURL, target: "_blank" }, { children: _jsx("button", __assign({ className: "orange-button", style: { width: "150px", height: "38px" } }, { children: "Baixar materiais" })) })) })) : (_jsx("button", __assign({ className: "orange-button", style: { width: "150px", height: "38px" } }, { children: "Baixar materiais" }))) })] })), _jsxs("div", __assign({ className: "container-for-uploading-artwork" }, { children: [_jsx("div", __assign({ className: "box-art-file-items" }, { children: _jsx(ArtFile, {}) })), _jsx("div", __assign({ className: "box-add-file" }, { children: _jsxs("button", __assign({ className: "button-add-file", type: "button", "data-bs-toggle": "modal", "data-bs-target": "#exampleModal", onClick: upload }, { children: [_jsx(AddIcon, {}), "Adicionar arquivo"] })) }))] })), _jsx(ModalToSendTheArt, { show: modalShow, closeModal: function () { setModalShow(false); } })] })));
}
export default UploadingArts;
