var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../styles/CustomerSuccessTeam.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import anaSarahCS from "../../App/assets/cs-ana-sarah.png";
import diegoGoncalvesCS from "../../App/assets/diego-goncalves.png";
import vanessaMartinsCS from "../../App/assets/vanessa-martins.png";
import janneQueirozCS from "../../App/assets/janne-queiroz.png";
import tailaCristinaCS from "../../App/assets/taila-cristina.png";
import beatrizMattosCS from "../../App/assets/cs-beatriz.png";
import caioXavierCS from "../../App/assets/cs-caio.png";
import calbyMedeirosCS from "../../App/assets/calby-medeiros.png";
function CustomerSuccessTeam() {
    var customersSuccess = [
        {
            photo: janneQueirozCS,
            nameCS: "Janne Queiroz",
            telephone: "(55) 21 97502-2127",
            email: "janne.queiroz@celeti.com.br"
        },
        {
            photo: anaSarahCS,
            nameCS: "Ana Sarah",
            telephone: "(55) 21 99401-7363",
            email: "ana.lins@celetihub.com.br"
        },
        {
            photo: diegoGoncalvesCS,
            nameCS: "Diego Gonçalves",
            telephone: "(55) 21 99862-4261",
            email: "diego.goncalves@celetihub.com.br"
        },
        {
            photo: vanessaMartinsCS,
            nameCS: "Vanessa Martins",
            telephone: "(55) 21 97502-9839",
            email: "vanessa.martins@celetihub.com.br"
        },
        {
            photo: calbyMedeirosCS,
            nameCS: "Calby Medeiros",
            telephone: "",
            email: "calby.medeiros@celetihub.com.br"
        },
        {
            photo: beatrizMattosCS,
            nameCS: "Beatriz Mattos",
            telephone: "",
            email: "beatriz.mattos@celetihub.com.br"
        },
        {
            photo: tailaCristinaCS,
            nameCS: "Taila Cristina",
            telephone: "(55) 21 97493-6295",
            email: "taila.anunciacao@celetihub.com.br"
        },
        {
            photo: caioXavierCS,
            nameCS: "Caio Xavier",
            telephone: "(55) 21 4020-1697",
            email: "caio.xavier@celetihub.com.br"
        },
    ];
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "container-customer-success-team", id: "customer-success-team" }, { children: [_jsx("h2", __assign({ className: "title-customer-success-team" }, { children: "Nosso Time de Customer Success" })), _jsx("p", __assign({ className: "text-customer-success-team" }, { children: "Sempre pronto para te atender" })), _jsxs("div", __assign({ className: "box-information-contact-customers" }, { children: [_jsx("div", __assign({ className: "contact-customers" }, { children: customersSuccess.map(function (item) { return (_jsxs("div", __assign({ className: "contact-customers-item" }, { children: [_jsx("img", { src: item.photo, alt: "Imagem de perfil do Customer Success" }), _jsxs("div", { children: [_jsx("p", { children: item.nameCS }), _jsx("span", { children: "Sucesso do Cliente" }), _jsxs("div", __assign({ className: "customer-item-contact" }, { children: [item.telephone &&
                                                        _jsx("p", { children: item.telephone }), _jsx("p", { children: item.email })] }))] })] }))); }) })), _jsxs("div", __assign({ className: "box-contact-member-handbook" }, { children: [_jsx("p", { children: "Siga-nos nas redes sociais e principais canais:" }), _jsxs("div", { children: [_jsx("a", __assign({ href: "https://www.facebook.com/celetihub", target: "_blank" }, { children: _jsx(FacebookIcon, { sx: { fontSize: "3rem" } }) })), _jsx("a", __assign({ href: "https://www.instagram.com/celetihub/", target: "_blank" }, { children: _jsx(InstagramIcon, { sx: { fontSize: "3rem" } }) })), _jsx("a", __assign({ href: "https://www.youtube.com/c/Celetioficial", target: "_blank" }, { children: _jsx(YouTubeIcon, { sx: { fontSize: "3rem" } }) })), _jsx("a", __assign({ href: "https://www.linkedin.com/company/celeti-tecnologia/", target: "_blank" }, { children: _jsx(LinkedInIcon, { sx: { fontSize: "3rem" } }) }))] })] }))] }))] })) }));
}
export default CustomerSuccessTeam;
