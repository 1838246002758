import useAuth from "./useAuth";
export var useAuthorize = function () {
    var auth = useAuth().auth;
    var hasPermission = function (permission) {
        return auth.permissions.includes(permission);
    };
    var hasAnyPermission = function (permissions) {
        return permissions.some(function (permission) {
            return auth.permissions.includes(permission);
        });
    };
    return { hasPermission: hasPermission, hasAnyPermission: hasAnyPermission };
};
