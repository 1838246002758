var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "../styles/first-access.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../../App/services/auth";
import Notification from "../../App/components/Notification";
import Footer from "../../App/components/Footer";
import ReplyIcon from "@mui/icons-material/Reply";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
function ForgotPassword() {
    var _this = this;
    var navigate = useNavigate();
    var _a = useForm({ mode: "onChange" }), register = _a.register, handleSubmit = _a.handleSubmit, watch = _a.watch, _b = _a.formState, isDirty = _b.isDirty, isValid = _b.isValid, errors = _b.errors, isSubmitting = _b.isSubmitting;
    var searchParams = useSearchParams()[0];
    var token = searchParams.get("token") || "";
    var password = useRef({});
    password.current = watch("password", "");
    var _c = useState(false), countLetterPassword = _c[0], setCountLetterPassword = _c[1];
    var _d = useState(false), passwordHasNumber = _d[0], setPasswordHasNumber = _d[1];
    var _e = useState(false), passwordHasCapitalize = _e[0], setPasswordHasCapitalize = _e[1];
    var _f = useState(false), showPassword = _f[0], setShowPassword = _f[1];
    var _g = useState(false), showPassword2 = _g[0], setShowPassword2 = _g[1];
    useEffect(function () {
        if (token == "") {
            //TODO: Adicionar uma flash message aqui e redirecionar.
            navigate("/login");
        }
    }, []);
    var submit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    request = {
                        password: data.password,
                        password_confirmation: data.password_repeat,
                        token: token,
                    };
                    Notification().Loading.dots("Alterando senha");
                    return [4 /*yield*/, forgotPassword(request).then(function (res) {
                            Notification().Loading.remove();
                            Notification().Notify.success(res.data.success);
                            navigate("/customers/password_changed");
                        }, function (err) {
                            Notification().Loading.remove();
                            if (err.response.status === 422 ||
                                err.response.status === 400 ||
                                err.response.status === 500) {
                                Notification().Notify.failure(err.response.data.error);
                            }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handlePasswordChange = function (password) {
        if (password.length >= 8) {
            setCountLetterPassword(true);
        }
        else {
            setCountLetterPassword(false);
        }
        if (/\d/.test(password)) {
            setPasswordHasNumber(true);
        }
        else {
            setPasswordHasNumber(false);
        }
        if (/[A-Z]/.test(password)) {
            setPasswordHasCapitalize(true);
        }
        else {
            setPasswordHasCapitalize(false);
        }
        return true;
    };
    var handleShowPasswordClick = function () {
        setShowPassword(!showPassword);
    };
    var handleShowPasswordClick2 = function () {
        setShowPassword2(!showPassword2);
    };
    return (_jsxs("div", __assign({ className: "container" }, { children: [_jsxs("div", __assign({ className: "row " }, { children: [_jsxs("div", __assign({ className: "d-flex gap-2" }, { children: [_jsx(ReplyIcon, { className: "icon-size align-self-baseline enabled-click", onClick: function () { return navigate(-1); } }), _jsx("h2", __assign({ className: "title-password" }, { children: "Alterar senha" }))] })), _jsx("hr", {}), _jsx("div", __assign({ className: "col-md-12 mx-auto col-lg-12 container-forms" }, { children: _jsx("div", __assign({ className: "rounded-3 bg-forms-password" }, { children: _jsx("div", { children: _jsxs("form", __assign({ onSubmit: handleSubmit(submit), className: "box-forms" }, { children: [_jsx("div", __assign({ className: "form-floating mb-4 box-input-password" }, { children: _jsxs("span", { children: [_jsxs("p", __assign({ className: "text-password" }, { children: ["Nova senha", _jsx("span", __assign({ className: "input-required" }, { children: "*" }))] })), _jsxs("div", __assign({ className: "box-input-show-password" }, { children: [_jsx("input", __assign({ required: true, 
                                                                // style={{ borderColor: countLetterPassword ? "red" : "green" || passwordHasNumber ? "red" : "green" || passwordHasCapitalize ? "red" : "green" }}
                                                                type: showPassword
                                                                    ? "password"
                                                                    : "text", className: "form-control input-password", id: "password", placeholder: "digite sua senha" }, register("password", {
                                                                validate: handlePasswordChange,
                                                            }))), _jsx("button", __assign({ className: "button-eye btn-show-password", onClick: handleShowPasswordClick, type: "button" }, { children: showPassword ? (_jsx(VisibilityOffIcon, {})) : (_jsx(VisibilityIcon, {})) }))] }))] }) })), _jsx("div", __assign({ className: "form-floating mb-4 box-input-password" }, { children: _jsxs("span", { children: [_jsxs("p", __assign({ className: "text-password" }, { children: ["Confirmar nova senha", _jsx("span", __assign({ className: "input-required" }, { children: "*" }))] })), _jsxs("div", __assign({ className: "box-input-show-password" }, { children: [_jsx("input", __assign({ required: true, 
                                                                // style={{ borderColor: countLetterPassword ? "red" : "green" || passwordHasNumber ? "red" : "green" || passwordHasCapitalize ? "red" : "green" }}
                                                                type: showPassword2
                                                                    ? "password"
                                                                    : "text", className: "form-control input-password", id: "password_repeat", placeholder: "repita a sua senha" }, register("password_repeat", {
                                                                validate: function (value) {
                                                                    return value ===
                                                                        password.current ||
                                                                        "As senhas não coincidem";
                                                                },
                                                            }))), _jsx("button", __assign({ className: "button-eye btn-show-password", onClick: handleShowPasswordClick2, type: "button" }, { children: showPassword2 ? (_jsx(VisibilityOffIcon, {})) : (_jsx(VisibilityIcon, {})) }))] })), errors.password_repeat && (_jsx("small", { children: errors.password_repeat.message }))] }) })), _jsxs("div", __assign({ className: "d-flex flex-column box-validate" }, { children: [_jsxs("p", __assign({ className: "text-validate", style: {
                                                        color: countLetterPassword
                                                            ? "green"
                                                            : "red",
                                                    } }, { children: [_jsx("small", { children: countLetterPassword ? "✓ " : "⨯ " }), "m\u00EDnimo de 8 caracteres"] })), _jsxs("p", __assign({ className: "text-validate", style: {
                                                        color: passwordHasNumber
                                                            ? "green"
                                                            : "red",
                                                    } }, { children: [_jsx("small", { children: passwordHasNumber ? "✓ " : "⨯ " }), "cont\u00E9m um ou mais n\u00FAmeros"] })), _jsxs("p", __assign({ className: "text-validate", style: {
                                                        color: passwordHasCapitalize
                                                            ? "green"
                                                            : "red",
                                                    } }, { children: [_jsx("small", { children: passwordHasCapitalize
                                                                ? "✓ "
                                                                : "⨯ " }), "uma letra mai\u00FAscula"] }))] })), _jsxs("div", __assign({ className: "d-flex justify-content-center align-items-center gap-4" }, { children: [_jsx("button", __assign({ className: " btn-super button-submit", style: { width: "15.625rem" }, type: "submit" }, { children: "Confirmar" })), _jsx("button", __assign({ className: " btn-super-blue btn-password-cancel", style: { width: "15.625rem" }, onClick: function () { return navigate(-1); } }, { children: "Cancelar" }))] }))] })) }) })) }))] })), _jsx("div", __assign({ className: "footer-sendmailchangepassword" }, { children: _jsx(Footer, {}) }))] })));
}
export default ForgotPassword;
