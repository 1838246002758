var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Modal from 'react-bootstrap/Modal';
import "./../styles/ModalToSendTheArt.css";
import DrangAndDropReview from './DrangAndDropReview';
function ModalToSendTheArt(props) {
    return (_jsx(_Fragment, { children: _jsx("form", __assign({ onSubmit: props.handleSubmit }, { children: _jsxs(Modal, __assign({}, props, { size: "lg", "aria-labelledby": "contained-modal-title-vcenter", centered: true, className: "modal-upload" }, { children: [_jsx(Modal.Header, { children: _jsx(Modal.Title, __assign({ id: "contained-modal-title-vcenter" }, { children: "Adicionar link" })) }), _jsx(Modal.Body, { children: _jsx(DrangAndDropReview, { closeModal: props.closeModal, pageName: "uploadArts" }) })] })) })) }));
}
export default ModalToSendTheArt;
