var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import '../styles/first-access.css';
import SmallLogoHeader from '../components/SmallLogoHeader';
function SendMailChangePasswordSuccess() {
    return (_jsxs(_Fragment, { children: [_jsx(SmallLogoHeader, {}), _jsx("div", __assign({ className: "container mt-5" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-md-12 mx-auto col-lg-12" }, { children: _jsx("div", __assign({ className: "rounded-3 bg-welcome-success" }, { children: _jsxs("div", __assign({ className: "col-md-5 p-4 p-md-5" }, { children: [_jsx("h2", { children: "Sucesso!" }), _jsx("p", __assign({ className: "welcome-chub" }, { children: "O e-mail foi enviado com sucesso!" })), _jsx("p", __assign({ className: "welcome-chub" }, { children: "Siga as instru\u00E7\u00F5es enviadas para o seu e-mail para efetuar a troca de senha." }))] })) })) })) })) }))] }));
}
export default SendMailChangePasswordSuccess;
