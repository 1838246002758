var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadingIcon from "@mui/icons-material/Downloading";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import "../styles/ImportSubscribers.css";
import { CSVLink } from "react-csv";
import csvIcon from "../../App/assets/csv-icon.png";
import "../../App/styles/GlobalStyle.css";
var FileCard = function (props) {
    var _a, _b, _c, _d;
    function bytesToSize(bytes) {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0)
            return "n/a";
        var i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024));
        if (i === 0)
            return "".concat(bytes.toFixed(2)).concat(sizes[i]);
        return "".concat((bytes / Math.pow(1024, i)).toFixed(2)).concat(sizes[i]);
    }
    function handleStatusIcon(statusName) {
        switch (statusName) {
            case "success":
                return (_jsx("abbr", __assign({ title: "Sucesso", className: "d-flex align-items-center" }, { children: _jsx(CheckCircleIcon, { style: { color: "#28A745" } }) })));
            case "error":
                return (_jsx("abbr", __assign({ title: "Ocorreu um erro", className: "d-flex align-items-center" }, { children: _jsx(CheckCircleIcon, { style: { color: "#DC3545" } }) })));
            case "warning":
                return (_jsx("abbr", __assign({ title: "Alguns erros foram encontrados", className: "d-flex align-items-center" }, { children: _jsx(ErrorIcon, { style: { color: "#EA5A1C" } }) })));
            default:
                return "";
        }
    }
    return (_jsxs("div", __assign({ className: "bg-light d-flex flex-row justify-content-between align-items-center rounded-3 shadow mb-4 py-2", style: { marginTop: "40px" } }, { children: [_jsxs("div", __assign({ className: "px-3 p-2 d-flex", style: { gap: "15px" } }, { children: [_jsx("img", { src: csvIcon, alt: "\u00EDcone de csv", style: { color: "#000000", width: "2.7rem", height: "3rem" } }), _jsxs("div", { children: [_jsxs("div", __assign({ className: "d-inline-flex align-items-center" }, { children: [_jsx("span", __assign({ className: "h5", style: {
                                            marginBottom: "2px",
                                            width: "210px",
                                            display: "block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                        } }, { children: props.name })), handleStatusIcon(props.fileStatus)] })), _jsx("p", __assign({ className: "text-muted h6 margin-top" }, { children: bytesToSize(props.size) }))] })] })), !props.processed ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: { flex: 7 }, className: "p-2" }, { children: [_jsx("div", { style: {
                                    borderRadius: "5px",
                                    backgroundColor: "#d9d9d9",
                                    height: "10px",
                                    width: "100%",
                                } }), props.finalProgress !== 100 ? (_jsx("div", { style: {
                                    marginTop: "-10px",
                                    borderRadius: "5px",
                                    backgroundColor: "#5AA3FF",
                                    height: "10px",
                                    width: props.progress + "%",
                                } })) : (_jsx("div", { style: {
                                    marginTop: "-10px",
                                    borderRadius: "5px",
                                    backgroundColor: "#5AA3FF",
                                    height: "10px",
                                    width: "100%",
                                } }))] })), _jsx("div", __assign({ style: { flex: 1 }, className: "d-flex align-items-center justify-content-center" }, { children: _jsx("button", __assign({ type: "submit", className: "btn" }, { children: _jsx(PlayArrowIcon, { style: { color: "#DC3545" } }) })) }))] })) : (_jsx(_Fragment, { children: props.resultData !== undefined ? (_jsxs("div", __assign({ className: "d-flex align-items-center gap-4 px-2" }, { children: [_jsxs("div", __assign({ className: "info d-inline-flex info-gap" }, { children: [_jsxs("div", __assign({ className: "d-flex flex-column justify-content-between align-items-center info-item" }, { children: [_jsx("span", __assign({ className: "info-text" }, { children: "Assinantes Adicionados" })), _jsxs("span", __assign({ className: "data-text" }, { children: [(_a = props.resultData) === null || _a === void 0 ? void 0 : _a.registered, " /", " ", (_b = props.resultData) === null || _b === void 0 ? void 0 : _b.totalSubscribers] }))] })), _jsxs("div", __assign({ className: "d-flex flex-column justify-content-between align-items-center info-item" }, { children: [_jsx("span", __assign({ className: "info-text" }, { children: "Servi\u00E7os Ativados" })), _jsxs("span", __assign({ className: "data-text" }, { children: [(_c = props.resultData) === null || _c === void 0 ? void 0 : _c.provisioned, " /", " ", (_d = props.resultData) === null || _d === void 0 ? void 0 : _d.totalServices] }))] }))] })), _jsx("div", __assign({ style: { width: "300px", textAlign: "center" } }, { children: props.fileStatus === "warning" &&
                                props.csvData !== undefined ? (_jsx(CSVLink, __assign({ data: props.csvData, filename: props.csvName, target: "_blank", enclosingCharacter: "" }, { children: _jsxs("div", __assign({ className: "btn button-download" }, { children: [_jsx(DownloadingIcon, {}), " Baixar Relat\u00F3rio de Erros"] })) }))) : ("---") })), _jsx("div", __assign({ className: "icon-container d-flex align-items-center justify-content-end" }, { children: _jsx("button", __assign({ className: "btn", onClick: function () { return props.callbackDelete(); } }, { children: _jsx(DeleteForeverIcon, { style: { color: "#000" } }) })) }))] }))) : (_jsx("div", __assign({ className: "d-flex justify-content-center align-items-center", style: { flex: 2 } }, { children: _jsx(MoreHorizIcon, { style: { color: "#000000" } }) }))) }))] })));
};
export default FileCard;
