var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
var RowsPerPage = function (props) {
    var options = [20, 30, 50];
    function handleChange(event) {
        props.callback(Number(event.target.value));
    }
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "d-flex" }, { children: [_jsx("span", { children: "Exibir por p\u00E1gina:" }), _jsx("select", __assign({ className: "selectRows", onChange: handleChange }, { children: options.map(function (item, index) {
                        return (_jsxs("option", __assign({ value: item }, { children: [" ", item] }), index));
                    }) }))] })) }));
};
export default RowsPerPage;
