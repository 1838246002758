import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
var RequirePermission = function (props) {
    var auth = useAuth().auth;
    return (auth.permissions.includes(props.permission) ?
        _jsx(Outlet, {}) :
        _jsx(Navigate, { to: "/", replace: true }));
};
export default RequirePermission;
