import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
var RequireNoUnpaidBills = function () {
    var auth = useAuth().auth;
    return (auth.signed && auth.has_unpaid_bills === 1 ?
        _jsx(Navigate, { to: "/overdue", replace: true }) :
        _jsx(Outlet, {}));
};
export default RequireNoUnpaidBills;
