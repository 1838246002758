var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import CustomerService from '../../Customer/services/CustomerService';
import Notification from '../../App/components/Notification';
import "../styles/ModalActiveCampaing.css";
function ModalActiveCampaing(props) {
    var _a = useState([]), selectedCampaings = _a[0], setSelectedCampains = _a[1];
    var activatedCampaignIds = props.campaingsId;
    useEffect(function () {
        setSelectedCampains([]);
    }, [props.show]);
    var handleCheckBox = function (campaignId) {
        setSelectedCampains(function (prev) {
            if (prev.includes(campaignId)) {
                return prev.filter(function (id) { return id !== campaignId; });
            }
            else {
                return __spreadArray(__spreadArray([], prev, true), [campaignId], false);
            }
        });
    };
    function AddCustomerCampaing(selectedCampaings, customerId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, CustomerService.AddCustomerCampaing({ selectedCampaings: selectedCampaings, customerId: customerId }).then(function (res) {
                            Notification().Notify.success('Campanhas ativadas');
                            Notification().Block.remove("[data-id=providers-grid]");
                            props.refreshProviders();
                        }, function (err) {
                            if (err.response.status === 403) {
                                Notification().Notify.failure(err.response.data.response.error);
                                Notification().Notify.failure('Erro ao ativar campanha');
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs(Modal, __assign({}, props, { size: "sm", "aria-labelledby": "contained-modal-title-vcenter", centered: true, className: "modal-campaign" }, { children: [_jsx(Modal.Header, __assign({ className: "title-modal" }, { children: _jsxs(Modal.Title, __assign({ className: "modal-campaign-title" }, { children: ["Ativar campanhas para ", _jsx("strong", { children: props.customerName })] })) })), _jsx(Modal.Body, { children: _jsx("div", __assign({ className: "container" }, { children: _jsx("div", __assign({ className: "row" }, { children: props.campaings && props.campaings.length > 0 ? (props.campaings && props.campaings.map(function (campaign) { return (_jsxs("div", __assign({ className: "input-campaign-name" }, { children: [_jsx("input", { type: "checkbox", id: "campaign-".concat(campaign.id), checked: selectedCampaings.includes(campaign.id), onChange: function () { return handleCheckBox(campaign.id); }, disabled: activatedCampaignIds === null || activatedCampaignIds === void 0 ? void 0 : activatedCampaignIds.includes(campaign.id) }), " ", (activatedCampaignIds === null || activatedCampaignIds === void 0 ? void 0 : activatedCampaignIds.includes(campaign.id)) ? (_jsx("label", __assign({ htmlFor: "campaign-".concat(campaign.id), className: "text-campaign-name" }, { children: campaign.name }))) : (_jsx("label", __assign({ htmlFor: "campaign-".concat(campaign.id) }, { children: campaign.name })))] }), campaign.id)); })) : (_jsx("p", { children: "Nenhuma campanha cadastrada" })) })) })) }), _jsxs(Modal.Footer, __assign({ className: "modal-footer d-flex justify-content-center", style: { display: 'flex', flexWrap: 'nowrap' } }, { children: [_jsx("button", __assign({ onClick: function () {
                            if (selectedCampaings.length === 0) {
                                Notification().Notify.failure("Escolha ao menos uma campanha");
                            }
                            else {
                                AddCustomerCampaing(selectedCampaings, props.customerId);
                                props.closeModal();
                            }
                        }, className: "close-button" }, { children: "Ativar" })), _jsx("button", __assign({ onClick: props.closeModal, className: "close-button", style: { backgroundColor: '#0047AB' }, id: "modal-campaign-button-close" }, { children: "Fechar" }))] }))] })));
}
export default ModalActiveCampaing;
