import { api } from "../../App/services/api";
function allProducts(contentSupplierId) {
    return api.get("/content_suppliers/".concat(contentSupplierId, "/products"));
}
function allContent() {
    return api.get("/content_suppliers");
}
function getActiveContentSupplierProducts($subscriberId) {
    return $subscriberId !== undefined ?
        api.get("/products/".concat($subscriberId)) :
        api.get("/products");
}
export default {
    allProducts: allProducts,
    allContent: allContent,
    getActiveContentSupplierProducts: getActiveContentSupplierProducts
};
