import Notiflix from "notiflix";
var Notification = function () {
    Notiflix.Notify.init({
        clickToClose: true,
        success: {
            background: '#1a4892'
        },
        info: {
            background: '#ce530f',
        },
        zindex: 9999
    });
    Notiflix.Block.init({
        svgColor: '#1a4892',
        backgroundColor: 'rgba(0,0,0,0.1)',
        zindex: 9999
    });
    Notiflix.Confirm.init({
        titleColor: '#000000',
        okButtonBackground: '#EA5A1C',
        messageMaxLength: 1100,
        zindex: 9999
    });
    Notiflix.Report.init({
        plainText: false,
        width: '500px',
        fontFamily: 'Outfit',
        titleFontSize: '22px',
        success: {
            svgColor: '#1a4892',
            buttonBackground: '#1a4892'
        },
        warning: {
            svgColor: '#ce530f',
            buttonBackground: '#ce530f'
        },
        info: {
            svgColor: '#EA5A1C',
            buttonBackground: '#EA5A1C'
        },
        zindex: 9999
    });
    Notiflix.Loading.init({
        svgColor: '#1a4892',
        zindex: 9999
    });
    return Notiflix;
};
export default Notification;
