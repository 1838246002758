var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubscriberService from "../services/SubscriberService";
import { documentFormatter } from "../../App/utils/formatter";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltOffRoundedIcon from "@mui/icons-material/FilterAltOffRounded";
import "../../App/styles/Subscribers.css";
import { Tooltip } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SubscriberActivityReport from "./SubscriberActivityReport";
import Notification from "../../App/components/Notification";
import { PriorityHigh } from "@mui/icons-material";
import { Can, CanAny } from "../../App/utils/Authorize";
import "../../App/styles/GlobalStyle.css";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import useAuth from "../../App/hooks/useAuth";
var SubscribersList = function (props) {
    var _a;
    var navigate = useNavigate();
    var _b = useState(false), checkedAll = _b[0], setCheckedAll = _b[1];
    var _c = useState([]), selectedData = _c[0], setSelectedData = _c[1];
    var _d = useState(false), modalShow = _d[0], setModalShow = _d[1];
    var _e = useState([]), subscriberHistory = _e[0], setSubscriberHistory = _e[1];
    var _f = useState(''), subscriberNameReport = _f[0], setSubscriberNameReport = _f[1];
    var selectOptions = [
        { label: "Editar dados", provisioner: false },
        { label: "Excluir", provisioner: false },
    ];
    var auth = useAuth().auth;
    useEffect(function () {
        if (props.subscribersList.length === 0) {
            setCheckedAll(false);
        }
        else {
            var selected_1 = __spreadArray([], selectedData, true);
            var documents = props.subscribersList.map(function (item) {
                return item.document;
            });
            var containsAll = documents.every(function (item) {
                return selected_1.includes(item);
            });
            setCheckedAll(containsAll);
        }
    }, [props.subscribersList, selectedData]);
    useEffect(function () {
        if (props.clearSelected) {
            setSelectedData([]);
        }
    }, [props.clearSelected]);
    useEffect(function () {
        props.callbackCheckbox(selectedData);
    }, [selectedData]);
    function editSubscriber(subscriberData, contentSuppliersProducts, tab) {
        navigate("/subscribers/".concat(subscriberData.id, "/update"), {
            state: {
                tab: tab,
                subscriber: {
                    id: subscriberData.id,
                    name: subscriberData.name,
                    email: subscriberData.email,
                    document: subscriberData.document,
                    phone: subscriberData.phone,
                    internet_speed_id: subscriberData.internet_speed_id,
                    customer_plan: subscriberData.customer_plan,
                },
                services: subscriberData.services,
            },
        });
    }
    function handleCheckbox(document) {
        var updated = __spreadArray([], selectedData, true);
        var current = updated.filter(function (item) {
            return item === document;
        });
        if (current.length === 0) {
            updated.push(document);
        }
        else {
            updated = updated.filter(function (item) {
                return item !== document;
            });
        }
        setSelectedData(updated);
    }
    var selectAll = function (check) {
        var selected = __spreadArray([], selectedData, true);
        if (check) {
            var to_select = props.subscribersList
                .filter(function (item) {
                return !selected.includes(item.document);
            })
                .map(function (item) {
                return item.document;
            });
            setSelectedData(__spreadArray(__spreadArray([], selectedData, true), to_select, true));
            setCheckedAll(true);
        }
        else {
            var to_unselect_1 = props.subscribersList.map(function (item) {
                return item.document;
            });
            setSelectedData(selected.filter(function (item) {
                return !to_unselect_1.includes(item);
            }));
            setCheckedAll(false);
        }
    };
    function getSubscriberHistory(subscriber) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Notification().Loading.dots("Buscando Dados");
                        return [4 /*yield*/, SubscriberService.getSubscriberHistory(subscriber.id).then(function (res) {
                                setSubscriberHistory(res.data.histories.data);
                                setSubscriberNameReport(subscriber.name);
                                setModalShow(true);
                                Notification().Loading.remove();
                            }, function (error) {
                                Notification().Loading.remove();
                                if (error.response.status === 403) {
                                    Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                                }
                                else {
                                    Notification().Notify.failure(error.message);
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function dataForResendEmail(subscriber) {
        return __awaiter(this, void 0, void 0, function () {
            var servicesDataForResendEmail;
            return __generator(this, function (_a) {
                servicesDataForResendEmail = subscriber.services
                    .filter(function (item) { return item.status_id === 5 || item.status_id === 1; });
                props.callbackResendEmail(subscriber.document);
                return [2 /*return*/];
            });
        });
    }
    function hasServicesForResendEmail(subscriber) {
        return subscriber.services.some(function (item) { return item.status_id === 5 || item.status_id === 1; });
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "responsive-table" }, { children: _jsxs("table", __assign({ className: "table table-borderless align-middle mb-0" }, { children: [_jsx("thead", { children: _jsxs("tr", __assign({ className: "table-header" }, { children: [_jsx("th", { children: _jsx("input", { className: "form-check-input", type: "checkbox", name: "selectAll", onChange: function (event) { return selectAll(event.target.checked); }, checked: checkedAll }) }), _jsx("th", { children: "Nome" }), _jsx("th", { children: "Email" }), _jsxs("th", __assign({ className: props.serviceValue !== 0 ? "column-flex column-filtered" : "column-flex" }, { children: [_jsxs("select", __assign({ className: props.serviceValue === 0
                                                    ? "form-select default-service-select"
                                                    : "form-select filtered-service-select", onChange: function (e) { return props.callbackFilterService(e); } }, { children: [_jsx("option", __assign({ hidden: true, value: 0, selected: props.serviceValue === 0, className: "select-option" }, { children: "Servi\u00E7os" })), props.contentSupplierProductsList != undefined ? (props.contentSupplierProductsList.length > 0 ? ((_a = props.contentSupplierProductsList) === null || _a === void 0 ? void 0 : _a.map(function (item, index) { return (_jsx("option", __assign({ value: item.id, selected: props.serviceValue === item.id, className: "select-option" }, { children: item.name }), index)); })) : (_jsx("option", __assign({ disabled: true }, { children: "Nenhum cadastrado" })))) : ("")] })), props.qtyErrors !== 0 &&
                                                _jsxs("span", __assign({ onClick: function () { return props.callbackFilterError(); }, className: "qty-errors" }, { children: [_jsx("b", { children: props.qtyErrors }), " ERROS"] }))] })), _jsx("th", { children: "CPF" }), _jsx("th", { children: "Telefone" }), _jsx("th", { children: _jsx(Tooltip, __assign({ title: "Limpar filtros", placement: "top", arrow: true }, { children: _jsx("button", __assign({ onClick: function () { return props.callbackFilterClear(); }, type: "button", style: {
                                                    border: "transparent",
                                                    color: "lightgray ",
                                                    background: "#fff ",
                                                } }, { children: _jsx(FilterAltOffRoundedIcon, {}) })) })) })] })) }), _jsx("tbody", __assign({ className: "td-line" }, { children: props.subscribersList.length > 0 ? (props.subscribersList.map(function (subscriber) {
                                return (_jsxs("tr", { children: [_jsx("td", { children: _jsx("div", __assign({ className: "form-check" }, { children: _jsx("input", { className: "form-check-input", type: "checkbox", checked: selectedData.includes(subscriber.document), name: subscriber.id.toString(), onChange: function () { handleCheckbox(subscriber.document); } }) })) }), _jsx("td", { children: subscriber.name }), _jsx("td", { children: subscriber.email }), _jsx("td", __assign({ style: props.serviceValue === 0
                                                ? { paddingLeft: "2px" }
                                                : { paddingLeft: "2px", backgroundColor: "rgba(234, 90, 28, .1)" } }, { children: subscriber.services.length === 0
                                                ? "-"
                                                : subscriber.services.map(function (item, key) {
                                                    var shouldBeExcluded = item.content_supplier_product_id === 6 || item.content_supplier_product_id === 7;
                                                    var isAuthorizedUser = auth.id === 173 || auth.id === 45;
                                                    if (shouldBeExcluded && !isAuthorizedUser) {
                                                        return null;
                                                    }
                                                    return (_jsxs("div", __assign({ style: { display: "flex", marginBottom: "3px" } }, { children: [_jsxs("div", __assign({ className: item.has_error ? "service-block service_with_error" : "service-block " }, { children: [_jsx(Tooltip, __assign({ title: item.token_status_translation, placement: "top", arrow: true }, { children: _jsx("span", __assign({ className: "material-symbols-outlined material-design-icon", style: { color: item === null || item === void 0 ? void 0 : item.icon_color, cursor: "pointer" }, onClick: function () { return getSubscriberHistory(subscriber); } }, { children: item.icon_name })) })), _jsx("span", __assign({ className: "service-name" }, { children: item.content_supplier_product_name }))] }), key), item.has_error === 1 ?
                                                                _jsx("div", __assign({ className: "fix-error-action-button button", onClick: function () { return props.callbackFixErrorAction(subscriber.document, item.content_supplier_product_id, item.status_id); } }, { children: _jsx(PriorityHigh, { className: "error-icon" }) }))
                                                                : null] }), key));
                                                }) })), _jsx("td", { children: documentFormatter(subscriber.document) }), _jsx("td", { children: formatPhoneNumberIntl(subscriber.phone) }), _jsx("td", __assign({ className: "provider-subscribers-action" }, { children: _jsx(CanAny, __assign({ permissions: ["update-subscriber", "delete-subscriber", "view-subscriberActivityReport"] }, { children: _jsxs(Dropdown, __assign({ className: "dropdownBootstrap" }, { children: [_jsx(Dropdown.Toggle, __assign({ className: "action-box-in-dropdown", id: "dropdown-basic" }, { children: _jsx(MoreHorizRoundedIcon, { className: "icon-that-shows-more-options" }) })), _jsxs(Dropdown.Menu, __assign({ className: "action-menu-in-dropdown" }, { children: [_jsx(Can, __assign({ permission: "update-subscriber" }, { children: _jsxs(Dropdown.Item, __assign({ value: "editar dados", className: "item-action-dropdown", onClick: function () {
                                                                            return editSubscriber(subscriber, props.contentSupplierProductsList, "updateSubscriber");
                                                                        } }, { children: [_jsx(EditIcon, {}), "Editar dados"] })) })), _jsx(Can, __assign({ permission: "delete-subscriber" }, { children: _jsxs(Dropdown.Item, __assign({ className: "item-action-dropdown", value: "excluir", onClick: function () { return props.callbackDeleteAction(subscriber.document); } }, { children: [_jsx(DeleteForeverIcon, {}), "Excluir"] })) })), _jsx(Can, __assign({ permission: "view-subscriberActivityReport" }, { children: _jsxs(Dropdown.Item, __assign({ className: "item-action-dropdown", onClick: function () { getSubscriberHistory(subscriber); } }, { children: [_jsx(DragIndicatorIcon, {}), "Relat\u00F3rio de Atividades"] })) })), hasServicesForResendEmail(subscriber) ? (_jsxs(Dropdown.Item, __assign({ className: "item-action-dropdown", onClick: function () { return dataForResendEmail(subscriber); } }, { children: [_jsx(RotateRightIcon, {}), " Reenvio de email"] }))) : null] }))] })) })) }))] }, subscriber.id));
                            })) : (_jsx("tr", { children: _jsxs("td", __assign({ className: "text-center", colSpan: 7 }, { children: [" ", props.searchingTerm !== ""
                                            ? "Nenhum assinante encontrado"
                                            : "Nenhum assinante cadastrado"] })) })) }))] })) })), _jsx(SubscriberActivityReport, { show: modalShow, subscriberHistory: subscriberHistory, subscriberNameReport: subscriberNameReport, closeModal: function () {
                    setModalShow(false);
                } })] }));
};
export default SubscribersList;
