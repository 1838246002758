import { api } from "../../App/services/api";
function getArts(per_page, status, designer, user_uploaded_art, sortBy, direction) {
    if (per_page === void 0) { per_page = 20; }
    if (status === void 0) { status = ''; }
    if (designer === void 0) { designer = ''; }
    if (user_uploaded_art === void 0) { user_uploaded_art = ''; }
    if (sortBy === void 0) { sortBy = ''; }
    if (direction === void 0) { direction = 'asc'; }
    var uri = "/arts?limit=".concat(per_page);
    if (status)
        uri += "&status=".concat(status);
    if (designer)
        uri += "&designer=".concat(designer);
    if (user_uploaded_art)
        uri += "&user_uploaded_art=".concat(user_uploaded_art);
    if (sortBy)
        uri += "&sortBy=".concat(sortBy);
    if (direction)
        uri += "&direction=".concat(direction);
    return api.get(uri);
}
function paginate(url, status, designer, user_uploaded_art, sortBy, direction) {
    url = (status === null || status === void 0 ? void 0 : status.length) === 0 ? url : url + "&status=".concat(status);
    url = (designer === null || designer === void 0 ? void 0 : designer.length) === 0 ? url : url + "&designer=".concat(designer);
    url = (user_uploaded_art === null || user_uploaded_art === void 0 ? void 0 : user_uploaded_art.length) === 0 ? url : url + "&user_uploaded_art=".concat(user_uploaded_art);
    url = (sortBy === null || sortBy === void 0 ? void 0 : sortBy.length) === 0 ? url : url + "&sortBy=".concat(sortBy);
    url = (direction === null || direction === void 0 ? void 0 : direction.length) === 0 ? url : url + "&direction=".concat(direction);
    return api.get(url);
}
function getArtDisapprovalOptions() {
    return api.get("/arts/disapproval_arts");
}
function sendEmailApproved(ArtsData) {
    return api.post('/arts/send-email-approved', ArtsData);
}
function storeDesignerAndStatus(payloadData) {
    return api.post("/arts/designer_and_status", payloadData);
}
function sendEmailDisapproved(ArtsData) {
    return api.post('/arts/send-email-disapproved', ArtsData);
}
function avaliationReview(artId) {
    return api.get("/arts/avaliation-review/".concat(artId));
}
function getAllStatus() {
    return api.get('/arts/arts_status');
}
export default {
    getArts: getArts,
    storeDesignerAndStatus: storeDesignerAndStatus,
    getArtDisapprovalOptions: getArtDisapprovalOptions,
    sendEmailApproved: sendEmailApproved,
    sendEmailDisapproved: sendEmailDisapproved,
    paginate: paginate,
    avaliationReview: avaliationReview,
    getAllStatus: getAllStatus
};
