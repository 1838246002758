export var activeStatusId = 1;
export var scheduleCancelStatusId = 2;
export var canceledStatusId = 3;
export var suspendStatusId = 4;
export var checkoutStatusId = 5;
export var limitReachedStatusId = 6;
export var overdueStatusId = 7;
export var scheduledSuspensionStatusId = 8;
export var revertStatusId = 9;
export var inProgressStatusId = 10;
export var reactivatedStatusId = 12;
export var changedStatusId = 13;
export var waitingSuspension = 14;
export var waitingCancellation = 15;
