import { jsx as _jsx } from "react/jsx-runtime";
import useAuth from "../hooks/useAuth";
import { PublicLayout } from "../layouts/PublicLayout";
import { PrivateLayout } from "../layouts/PrivateLayout";
export var LayoutProvider = function (props) {
    var auth = useAuth().auth;
    return (!auth.signed ?
        _jsx(PublicLayout, { children: props.children }) :
        _jsx(PrivateLayout, { children: props.children }));
};
