import { api } from "../../App/services/api";
function all(perPage, customer_status_id, plan_id, sort) {
    var uri = "/customers?limit=".concat(perPage);
    if (customer_status_id) {
        uri += "&customer_status_id=".concat(customer_status_id);
    }
    if (plan_id) {
        uri += "&plan_id=".concat(plan_id);
    }
    if (sort) {
        uri += "&sort=".concat(sort);
    }
    return api.get(uri);
}
function allProviders(perPage, customer_status_id, plan_id, sort) {
    var uri = "/customers/providers?limit=".concat(perPage);
    if (customer_status_id) {
        uri += "&customer_status_id=".concat(customer_status_id);
    }
    if (plan_id) {
        uri += "&plan_id=".concat(plan_id);
    }
    if (sort) {
        uri += "&sort=".concat(sort);
    }
    return api.get(uri);
}
function paginate(url, customer_status_id, plan_id, sort) {
    if (customer_status_id) {
        url += "&customer_status_id=".concat(customer_status_id);
    }
    if (plan_id) {
        url += "&plan_id=".concat(plan_id);
    }
    if (sort) {
        url += "&sort=".concat(sort);
    }
    return api.get(url);
}
function find(customerId) {
    return api.get("/customers/".concat(customerId));
}
function store(customerData) {
    return api.post('/customers', customerData);
}
function update(customerId, customerData) {
    return api.put("/customers/".concat(customerId), customerData);
}
function destroy(customerId) {
    return api.delete('/customers/' + "".concat(customerId));
}
function impersonate(customerId) {
    return api.get("/customers/impersonate/".concat(customerId));
}
function unImpersonateCustomer(request) {
    return api.post('/customers/unImpersonate', request);
}
function customUpdate(customerId) {
    return api.put('/customers/update/' + "".concat(customerId));
}
function getBlingCustomerData(document) {
    return api.post('/customers/bling_data', { document: document });
}
function getAllCustomersSuccess() {
    return api.get('/customers/customersSuccess');
}
function getAllClosers() {
    return api.get('/customers/closers');
}
function getAllSellers() {
    return api.get('/customers/sellers');
}
function getAllPlans() {
    return api.get('/customers/plans');
}
function getAllStatus() {
    return api.get('/customers/customerStatus');
}
function getAllErps() {
    return api.get('/customers/erps');
}
function getAllServices() {
    return api.get('/content_suppliers_products');
}
function getAllHubs() {
    return api.get('/customers/hubs');
}
function getAllOrganizations() {
    return api.get('/customers/organizations');
}
function search(providersSearch, perPage) {
    return api.get("/customers/search/".concat(providersSearch, "?limit=").concat(perPage));
}
function searchSupport(providersSearch) {
    return api.get("/customers/search_support/".concat(providersSearch));
}
function sendMail(customerId) {
    return api.get("/customers/send_first_email/".concat(customerId));
}
function handleOverdue(customerId) {
    return api.post("/customers/handle_overdue/".concat(customerId));
}
function getCustomerReport(reportId) {
    return api.get("/customers/reports/".concat(reportId));
}
function getAllCustomerReports() {
    return api.get("/customers/reports");
}
function handleProduct(payloadData) {
    return api.post('/customers/handle_product', payloadData);
}
function getActiveProducts(customerId, provider) {
    return api.get("/customers/".concat(customerId, "/products"));
}
function getCampaings() {
    return api.get('/awards/get_active_campaigns');
}
function AddCustomerCampaing(payloadData) {
    return api.post('/awards/add_customer_to_campaing', payloadData);
}
function getAllGracePeriods() {
    return api.get('/customers/grace-periods');
}
function changePlan(customerId, planId) {
    return api.post('/customers/change/plan', {
        customer_id: customerId,
        plan_id: planId,
    });
}
function changeGracePeriod(payloadData) {
    return api.post('customers/change/grace-period', payloadData);
}
function changeGracePeriodDate() {
    return api.get('customers/change/grace-period-date');
}
function sendDissolution(payloadData) {
    return api.post('customers/send/dissolution', payloadData);
}
export default {
    all: all,
    paginate: paginate,
    find: find,
    store: store,
    update: update,
    destroy: destroy,
    impersonate: impersonate,
    unImpersonateCustomer: unImpersonateCustomer,
    getBlingCustomerData: getBlingCustomerData,
    getAllCustomersSuccess: getAllCustomersSuccess,
    getAllClosers: getAllClosers,
    getAllSellers: getAllSellers,
    getAllPlans: getAllPlans,
    getAllStatus: getAllStatus,
    getAllErps: getAllErps,
    search: search,
    searchSupport: searchSupport,
    getAllServices: getAllServices,
    getAllHubs: getAllHubs,
    getAllOrganizations: getAllOrganizations,
    sendMail: sendMail,
    handleOverdue: handleOverdue,
    getCustomerReport: getCustomerReport,
    getAllCustomerReports: getAllCustomerReports,
    handleProduct: handleProduct,
    getActiveProducts: getActiveProducts,
    getCampaings: getCampaings,
    AddCustomerCampaing: AddCustomerCampaing,
    allProviders: allProviders,
    getAllGracePeriods: getAllGracePeriods,
    changePlan: changePlan,
    changeGracePeriod: changeGracePeriod,
    changeGracePeriodDate: changeGracePeriodDate,
    sendDissolution: sendDissolution,
};
