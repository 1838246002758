import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from "react-dom";
import "./index.css";
import "./fonts/Outfit/Outfit-VariableFont_wght.ttf";
import "./fonts/Font Awesome 6 Free/static/fa-regular-400.ttf";
import "./main-styles.css";
import App from "./App/pages/App";
import Tour from "./App/components/Tour";
import 'material-symbols';
ReactDOM.render(_jsx(Tour, { children: _jsx(App, {}) }), document.getElementById("root"));
