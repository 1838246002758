import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthProvider } from "../contexts/AuthProvider";
import { api } from '../services/api';
import cookies from '../services/cookies';
import MyRoutes from "../routes/MyRoutes";
import { LayoutProvider } from "../contexts/LayoutProvider";
import { BrowserRouter } from "react-router-dom";
function App() {
    if (cookies.get('token'))
        api.defaults.headers.common['Authorization'] = 'Bearer ' + cookies.get('token');
    return (_jsx(_Fragment, { children: _jsx(BrowserRouter, { children: _jsx(AuthProvider, { children: _jsx(LayoutProvider, { children: _jsx(MyRoutes, {}) }) }) }) }));
}
export default App;
