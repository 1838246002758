var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Content from "../components/Content";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DevEnvironmentFlag from "../components/DevEnvironmentFlag";
import { useEffect, useState } from "react";
import SideBar from "../components/SideBar";
export var PrivateLayout = function (props) {
    var _a = useState(false), sideBarIsOpen = _a[0], setSideBarIsOpen = _a[1];
    var _b = useState(false), mouseOver = _b[0], setMouseOver = _b[1];
    var _c = useState(false), hamburguerIcon = _c[0], setHamburguerIcon = _c[1];
    var prod_url = process.env.REACT_APP_FRONT_PROD_URL;
    var url = window.location.origin;
    function isDevEnvironment() {
        return url !== prod_url;
    }
    useEffect(function () {
        handleSidebar();
    }, [hamburguerIcon, mouseOver]);
    function handleMouseEnter() {
        setMouseOver(true);
    }
    function handleMouseLeave() {
        setMouseOver(false);
    }
    function handleHamburguerIcon() {
        setHamburguerIcon(!hamburguerIcon);
    }
    function handleSidebar() {
        if (hamburguerIcon) {
            setSideBarIsOpen(true);
        }
        else if (mouseOver && !hamburguerIcon) {
            setSideBarIsOpen(true);
        }
        else {
            setSideBarIsOpen(false);
        }
    }
    return (_jsxs(_Fragment, { children: [isDevEnvironment() ? _jsx(DevEnvironmentFlag, {}) : null, _jsx(Header, { isDevEnvironment: isDevEnvironment(), handleHamburguerIcon: handleHamburguerIcon, sideBarIsOpen: sideBarIsOpen }), _jsx(SideBar, { sideBarIsOpen: sideBarIsOpen, setSideBarIsOpen: setSideBarIsOpen, isDevEnvironment: isDevEnvironment(), handleMouseEnter: handleMouseEnter, handleMouseLeave: handleMouseLeave }), _jsx(Content, __assign({ isDevEnvironment: isDevEnvironment() }, { children: props.children })), _jsx(Footer, {})] }));
};
