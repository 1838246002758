import { api } from "../../App/services/api";
function storeManyArts(ArtsData) {
    return api.post('/arts/importArts', ArtsData);
}
function getUserArts(per_page) {
    var uri = "/arts/get-arts-for-user?limit=".concat(per_page);
    return api.get(uri);
}
function paginate(url) {
    return api.get(url);
}
function destroy(artsId) {
    return api.delete("/arts/".concat(artsId));
}
export default {
    storeManyArts: storeManyArts,
    getUserArts: getUserArts,
    paginate: paginate,
    destroy: destroy,
};
