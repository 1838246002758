import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
var CheckAuth = function () {
    var auth = useAuth().auth;
    return (auth.signed ?
        _jsx(Navigate, { to: "/", replace: true }) :
        _jsx(Outlet, {}));
};
export default CheckAuth;
