var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
function HorizontalChart(_a) {
    var data = _a.data, color = _a.color;
    var _b = useState([]), series = _b[0], setSeries = _b[1];
    useEffect(function () {
        var seriesData = [
            {
                data: data.map(function (item) { return ({
                    x: item.service,
                    y: item.value,
                    name: item.service,
                }); }),
                name: ""
            },
        ];
        setSeries(seriesData);
    }, [data]);
    var options = {
        theme: {
            mode: 'light',
            palette: 'palette1',
            monochrome: {
                enabled: true,
                color: color,
                shadeTo: 'light',
                shadeIntensity: 0.65
            },
        },
        chart: {
            type: "bar",
            fontFamily: "Outfit",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        grid: {
            show: false
        },
        xaxis: {
            axisBorder: {
                show: false,
            },
            categories: data.map(function (item) { return item.service; })
        },
        tooltip: {
            enabled: false,
        },
        responsive: [
            {
                breakpoint: 1400,
                options: {
                    chart: {
                        width: 300,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false
                        }
                    },
                    legend: {
                        position: "bottom"
                    }
                }
            },
        ],
    };
    return (_jsx("div", __assign({ id: "chart" }, { children: _jsx(ReactApexChart, { options: options, series: series, color: color, type: "bar", height: 200 }) })));
}
export default HorizontalChart;
