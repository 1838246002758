import Notification from './Notification';
export var handleServiceError = function (error) {
    if (error.response.status === 403) {
        Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
    }
    else if (error.response.status === 400) {
        Notification().Loading.remove();
        Notification().Notify.failure(error.response.data.error);
    }
    else if (error.response.status === 422) {
        Notification().Notify.failure(error.response.data.error);
    }
    else {
        Notification().Notify.failure(error.message || "Erro desconhecido.");
    }
    Notification().Block.remove("");
};
function ErrorHandler(_a) {
    var error = _a.error, serviceError = _a.serviceError;
    handleServiceError(error);
    return null;
}
export default ErrorHandler;
