export var adminRole = 1;
export var customerSuccessRole = 2;
export var customerRole = 3;
export var closerRole = 4;
export var sellerRole = 5;
export var ghostRole = 6;
export var hubRole = 7;
export var financialRole = 8;
export var attendantRole = 9;
export var customerEmployeeRole = 10;
export var designerRole = 12;
