var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CodeIcon from "@mui/icons-material/Code";
import CodeOffIcon from "@mui/icons-material/CodeOff";
function DevEnvironmentFlag() {
    return (_jsx("div", __assign({ className: "text-white", id: "container-dev-environment-flag", style: {
            backgroundColor: "red",
            position: "fixed",
            zIndex: "10",
            fontSize: "5px",
            top: "0",
            width: "100%"
        } }, { children: _jsx("div", __assign({ className: "container" }, { children: _jsx("div", __assign({ className: "text-center" }, { children: _jsxs("span", { children: [_jsx(CodeIcon, {}), " Este \u00E9 um ambiente de demonstra\u00E7\u00E3o. Nenhuma a\u00E7\u00E3o realizada ser\u00E1 v\u00E1lida. ", _jsx(CodeOffIcon, {})] }) })) })) })));
}
export default DevEnvironmentFlag;
