import Cookie from "universal-cookie";
var cookie = new Cookie();
function get(key) {
    return cookie.get(key);
}
function set(key, value, options) {
    cookie.set(key, value, options);
}
function remove(key) {
    cookie.remove(key);
}
export default {
    get: get,
    set: set,
    remove: remove
};
