var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import "../styles/CreationAndApprovalOfArts.css";
import "../../App/styles/GlobalStyle.css";
import backgroundCreationAndApprovalOfArts from "../../App/assets/background-creation-and-approval-of-arts.png";
function CreationAndApprovalOfArts() {
    return (_jsxs("div", __assign({ className: "container-creation-and-approval-of-arts", id: "creation-and-approval-of-arts" }, { children: [_jsx("img", { src: backgroundCreationAndApprovalOfArts, alt: "Imagem de fundo" }), _jsxs("div", __assign({ className: "box-information-creation-and-approval-of-arts" }, { children: [_jsx("h2", { children: "Aprova\u00E7\u00E3o de artes" }), _jsx("p", __assign({ className: "text-description-creation-and-approval-of-arts" }, { children: "Todo material promocional divulgando suas ofertas com nossos SVAs precisam passar pela aprova\u00E7\u00E3o do nosso super time de marketing e design, atrav\u00E9s da nossa plataforma Celeti HUB. J\u00E1 aprovou suas artes hoje? Clique no bot\u00E3o abaixo e fa\u00E7a seus envios e aguarde resposta at\u00E9 7 dias \u00FAteis." })), _jsx("div", __assign({ className: "box-buttons-creation-and-approval-of-arts" }, { children: _jsx(Link, __assign({ to: "/upload_arts" }, { children: _jsx("button", __assign({ className: "blue-button arts-approval-button" }, { children: "Aprova\u00E7\u00E3o de Artes" })) })) }))] }))] })));
}
export default CreationAndApprovalOfArts;
