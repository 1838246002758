import axios from 'axios';
import cookies from "./cookies";
export var api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});
api.interceptors.response.use(function (res) {
    return res;
}, function (err) {
    if (err.response.status === 401 && window.location.pathname !== '/login') {
        cookies.remove('token');
        cookies.remove('token_old');
        window.location.href = '/login';
    }
    else {
        return Promise.reject(err);
    }
});
