var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect, useState } from "react";
import "../styles/PageTabs.css";
var Tab = function (props) {
    var _a = useState(), tabClasses = _a[0], setTabClasses = _a[1];
    var _b = useState(), stepClasses = _b[0], setStepClasses = _b[1];
    var _c = useState(), lineClasses = _c[0], setlineClasses = _c[1];
    useLayoutEffect(function () {
        setTabClasses(getTabClass(props.activeTab));
        setStepClasses(getStepClass(props.activeTab));
        setlineClasses(getLineClass(props.activeTab));
    }, [props.activeTab]);
    function getTabClass(activeTab) {
        var newTabClasses = {
            updateSubscriber: {
                classes: "",
                click: false,
            },
            updateService: {
                classes: "",
                click: false,
            },
            confirmForm: {
                classes: "",
                click: false,
            },
        };
        switch (activeTab) {
            case "updateSubscriber":
                newTabClasses.updateSubscriber.classes = "active";
                break;
            case "updateService":
                newTabClasses.updateSubscriber.classes = "enabled-click";
                newTabClasses.updateService.classes = "active";
                newTabClasses.updateService.click = false;
                newTabClasses.updateSubscriber.click = true;
                break;
            case "confirmForm":
                newTabClasses.updateSubscriber.classes = "enabled-click";
                newTabClasses.updateService.classes = "enabled-click";
                newTabClasses.confirmForm.classes = "active";
                newTabClasses.updateSubscriber.click = true;
                newTabClasses.updateService.click = true;
                break;
        }
        return newTabClasses;
    }
    function getStepClass(activeTab) {
        var newStepClasses = {
            updateSubscriber: {
                classes: "",
                click: false,
            },
            updateService: {
                classes: "",
                click: false,
            },
            confirmForm: {
                classes: "",
                click: false,
            },
        };
        switch (activeTab) {
            case "updateSubscriber":
                newStepClasses.updateSubscriber.classes = "number-multistep1";
                newStepClasses.updateService.classes = "number-multistep2";
                newStepClasses.confirmForm.classes = "number-multistep3";
                break;
            case "updateService":
                newStepClasses.updateSubscriber.classes =
                    "number-multistep-check";
                newStepClasses.updateService.classes = "number-multistep-blue";
                newStepClasses.confirmForm.classes = "number-multistep3";
                break;
            case "confirmForm":
                newStepClasses.updateSubscriber.classes =
                    "number-multistep-check";
                newStepClasses.updateService.classes = "number-multistep-check";
                newStepClasses.confirmForm.classes = "number-multistep-blue3";
                break;
        }
        return newStepClasses;
    }
    function getLineClass(activeTab) {
        var newLineClasses = {
            updateSubscriber: {
                classes: "",
            },
            updateService: {
                classes: "",
            },
            confirmForm: {
                classes: "",
            },
        };
        switch (activeTab) {
            case "updateSubscriber":
                newLineClasses.updateSubscriber.classes = "line2";
                newLineClasses.updateService.classes = "line3";
                break;
            case "updateService":
                newLineClasses.updateSubscriber.classes = "line-blue";
                newLineClasses.updateService.classes = "line3";
                break;
            case "confirmForm":
                newLineClasses.updateSubscriber.classes = "line-blue";
                newLineClasses.updateService.classes = "line-blue2";
                break;
        }
        return newLineClasses;
    }
    return (_jsxs("div", __assign({ className: "d-flex justify-content-center subscriber-tab-header" }, { children: [_jsx("div", __assign({ className: tabClasses === null || tabClasses === void 0 ? void 0 : tabClasses.updateSubscriber.classes, onClick: function () {
                    return (tabClasses === null || tabClasses === void 0 ? void 0 : tabClasses.updateSubscriber.click)
                        ? props.callbackTab("updateSubscriber")
                        : null;
                } }, { children: _jsx("div", __assign({ className: "d-flex justify-content-center provider-subscribersNew" }, { children: _jsxs("div", { children: [_jsx("div", { className: stepClasses === null || stepClasses === void 0 ? void 0 : stepClasses.updateSubscriber.classes, onClick: function () {
                                    return (stepClasses === null || stepClasses === void 0 ? void 0 : stepClasses.updateSubscriber.click)
                                        ? props.callbackTab("updateSubscriber")
                                        : null;
                                } }), _jsx("div", { children: "Dados" })] }) })) })), _jsx("div", { className: lineClasses === null || lineClasses === void 0 ? void 0 : lineClasses.updateSubscriber.classes }), _jsxs("div", __assign({ className: tabClasses === null || tabClasses === void 0 ? void 0 : tabClasses.updateService.classes, id: "first-circle", onClick: function () {
                    return (tabClasses === null || tabClasses === void 0 ? void 0 : tabClasses.updateService.click)
                        ? props.callbackTab("updateService")
                        : null;
                } }, { children: [" ", _jsxs("div", __assign({ className: "provider-subscribersNew-services" }, { children: [_jsx("div", { className: stepClasses === null || stepClasses === void 0 ? void 0 : stepClasses.updateService.classes, onClick: function () {
                                    return (stepClasses === null || stepClasses === void 0 ? void 0 : stepClasses.updateSubscriber.click)
                                        ? props.callbackTab("updateSubscriber")
                                        : null;
                                } }), _jsx("div", __assign({ style: { marginLeft: "-0.5rem" } }, { children: "Servi\u00E7os" }))] }))] })), _jsx("div", { className: lineClasses === null || lineClasses === void 0 ? void 0 : lineClasses.updateService.classes }), _jsx("div", __assign({ className: tabClasses === null || tabClasses === void 0 ? void 0 : tabClasses.confirmForm.classes, id: "third-circle", onClick: function () {
                    return (tabClasses === null || tabClasses === void 0 ? void 0 : tabClasses.confirmForm.click)
                        ? props.callbackTab("confirmForm")
                        : null;
                } }, { children: _jsxs("div", __assign({ className: "provider-subscribersNew-services-confirm" }, { children: [_jsx("div", { className: stepClasses === null || stepClasses === void 0 ? void 0 : stepClasses.confirmForm.classes, onClick: function () {
                                return (stepClasses === null || stepClasses === void 0 ? void 0 : stepClasses.updateSubscriber.click)
                                    ? props.callbackTab("updateSubscriber")
                                    : null;
                            } }), _jsx("div", __assign({ style: { marginLeft: "-1rem" } }, { children: "Confirmar" }))] })) }))] })));
};
export default Tab;
