import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
var CheckOverdue = function () {
    var auth = useAuth().auth;
    return (auth.has_unpaid_bills === 1 ?
        _jsx(Outlet, {}) :
        _jsx(Navigate, { to: "/", replace: true }));
};
export default CheckOverdue;
