var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";
function Pie(_a) {
    var series = _a.series, labels = _a.labels, color = _a.color;
    var options = useState({
        labels: labels,
        theme: {
            mode: 'light',
            palette: 'palette1',
            monochrome: {
                enabled: true,
                color: color,
                shadeTo: 'light',
                shadeIntensity: 0.65
            },
        },
        chart: {
            type: "donut",
            fontFamily: "Outfit",
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "70px",
                    labels: {
                        show: true,
                        name: {
                            show: true,
                        },
                        total: {
                            label: "Total de assinantes",
                            showAlways: true,
                            show: true,
                            fontSize: "18px",
                            fontFamily: "Outfit",
                            color: "#949494",
                        },
                    },
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex];
            }
        },
        legend: {
            width: 210,
            height: 210,
            fontSize: '16px',
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
            position: 'right',
            markers: {
                width: 11,
                height: 11
            },
            labels: {
                colors: ["#949494"],
            },
            itemMargin: {
                horizontal: 15,
                vertical: 10
            }
        },
        responsive: [
            {
                breakpoint: 1400,
                options: {
                    chart: {
                        width: 300,
                    },
                }
            },
        ],
    })[0];
    return (_jsx("div", __assign({ id: "chart" }, { children: _jsx(ReactApexChart, { options: options, series: series, color: color, type: "donut", width: 550, height: 350 }) })));
}
export default Pie;
