var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import ContentSupplierService from "../services/ContentSupplierService";
import InfoIcon from "@mui/icons-material/Info";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "../styles/ServicesForm.css";
import { activeStatusId, canceledStatusId, checkoutStatusId, scheduleCancelStatusId, suspendStatusId, changedStatusId } from "../../App/helpers/TokenStatusHelper";
import Notification from "../../App/components/Notification";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { TokenStatusInformation } from "../../App/helpers/TokenStatusInformation";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Link } from "react-router-dom";
import "../../App/styles/GlobalStyle.css";
var ServicesForm = function (props) {
    var _a = useState(props.boxesData), boxesData = _a[0], setBoxesData = _a[1];
    var _b = useState(props.servicesAction), servicesAction = _b[0], setServicesAction = _b[1];
    var _c = useState(), groupLists = _c[0], setGroupLists = _c[1];
    var _d = useState(props.services), activeServicesList = _d[0], setActiveServicesList = _d[1];
    var _e = useState(props.subscriberData), subscriberInfo = _e[0], setSubscriberInfo = _e[1];
    function expired(expirationDate) {
        var expDate = new Date(expirationDate);
        var currentDate = new Date();
        return expDate < currentDate;
    }
    useEffect(function () {
        function getContentSuppliersProducts() {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var products, boxesData, groupList, boxToggle, services;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            products = [];
                            Notification().Loading.dots("Buscando Dados");
                            return [4 /*yield*/, ContentSupplierService.getActiveContentSupplierProducts(props.subscriberId).then(function (res) {
                                    products = res.data.content_supplier_products;
                                }, function (err) {
                                    if (err.response.status === 403) { }
                                })];
                        case 1:
                            _b.sent();
                            boxesData = [];
                            groupList = [];
                            boxToggle = true;
                            products.forEach(function (product) {
                                if ((product.active === 1) || (product.active === 0 && props.subscriberId !== undefined)) {
                                    groupList[product.group_id] = [];
                                }
                            });
                            products.forEach(function (product) {
                                if ((product.active === 1) || (product.active === 0 && props.subscriberId !== undefined)) {
                                    groupList[product.group_id].push(product);
                                }
                            });
                            services = (_a = props.services) === null || _a === void 0 ? void 0 : _a.filter(function (service) {
                                return service.status_id !== 3;
                            });
                            setActiveServicesList(services);
                            setGroupLists(groupList);
                            groupList.forEach(function (group) {
                                var tier = 0;
                                var subscriberService = null;
                                if (services !== undefined && services.length > 0) {
                                    var _loop_1 = function (i) {
                                        subscriberService = services.find(function (service) {
                                            return service.content_supplier_product_id === group[i].id;
                                        });
                                        if (subscriberService) {
                                            tier = group[i].tier;
                                            return "break";
                                        }
                                    };
                                    for (var i = 0; i < group.length; i++) {
                                        var state_1 = _loop_1(i);
                                        if (state_1 === "break")
                                            break;
                                    }
                                }
                                group.forEach(function (product) {
                                    if ((subscriberService &&
                                        (subscriberService.status_id !== activeStatusId) &&
                                        subscriberService.group_id === product.group_id &&
                                        (product.id !== subscriberService.content_supplier_product_id)) || (product.tier === tier &&
                                        product.active === 1 &&
                                        subscriberService.content_supplier_product_id !== product.id)) {
                                        boxToggle = false;
                                    }
                                    else {
                                        boxToggle = true;
                                    }
                                    var resultBoxData = creatingTheServiceBox(product, subscriberService, tier, boxToggle);
                                    boxesData.push(resultBoxData);
                                });
                            });
                            Notification().Loading.remove();
                            setBoxesData(boxesData);
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (boxesData.length === 0) {
            getContentSuppliersProducts();
        }
    }, [boxesData.length, activeServicesList, props.services]);
    function creatingTheServiceBox(product, subscriberService, tier, boxToggle) {
        var serviceBox = (subscriberService === null || subscriberService === undefined) || product.id !== subscriberService.content_supplier_product_id || (subscriberService.status_id === canceledStatusId) ? {
            service: __assign(__assign({}, product), { status_id: 0 }),
            toggle: false,
            options: getComboBoxStatus(product.active, 0, product.expiration_date),
            selectedAction: "select",
            newStatus: 0,
            provisioned: false,
            titleService: upgradeAndDowngradeServices(product, tier),
            divElement: divUpgradeMessage(product, boxToggle, tier),
            boxToggle: boxToggle,
            promotion: product.promotion,
        } : {
            service: __assign(__assign({}, product), { status_id: subscriberService.status_id }),
            toggle: true,
            options: getComboBoxStatus(product.active, subscriberService.status_id, product.expiration_date),
            selectedAction: getAction(subscriberService.status_id),
            newStatus: subscriberService.status_id,
            provisioned: true,
            currentStatus: getAction(subscriberService.status_id),
            titleService: upgradeAndDowngradeServices(product, tier),
            divElement: divUpgradeMessage(product, boxToggle, tier),
            boxToggle: boxToggle,
            promotion: product.promotion,
        };
        return serviceBox;
    }
    function getComboBoxStatus(active, statusId, expirationDate) {
        var expiredFlag = expired(expirationDate);
        if (statusId === activeStatusId || statusId === checkoutStatusId) {
            return (_jsxs(_Fragment, { children: [_jsx("option", __assign({ value: "activate" }, { children: "Ativo" })), _jsx("option", __assign({ value: "suspend" }, { children: "Suspenso" })), _jsx("option", __assign({ value: "deactivate" }, { children: "Cancelado" }))] }));
        }
        else if (statusId === scheduleCancelStatusId || statusId === canceledStatusId) {
            return (_jsxs(_Fragment, { children: [_jsx("option", __assign({ value: "deactivate" }, { children: "Cancelado" })), _jsx("option", __assign({ value: "activate" }, { children: "Ativo" }))] }));
        }
        else if (statusId === suspendStatusId) {
            if (active === 0 && expiredFlag) {
                return (_jsxs(_Fragment, { children: [_jsx("option", __assign({ value: "suspend" }, { children: "Suspenso" })), _jsx("option", __assign({ value: "deactivate" }, { children: "Cancelado" }))] }));
            }
            else {
                return (_jsxs(_Fragment, { children: [_jsx("option", __assign({ value: "suspend" }, { children: "Suspenso" })), _jsx("option", __assign({ value: "reactivate" }, { children: "Ativo" })), _jsx("option", __assign({ value: "deactivate" }, { children: "Cancelado" }))] }));
            }
        }
        else {
            return (_jsx(_Fragment, { children: _jsx("option", { children: "-------------" }) }));
        }
    }
    function getAction(statusId) {
        switch (statusId) {
            case scheduleCancelStatusId:
            case canceledStatusId:
                return "deactivate";
            case suspendStatusId:
                return "suspend";
            case changedStatusId:
                return "changed";
            default:
                return "activate";
        }
    }
    function getStatus(action) {
        switch (action) {
            case "activate":
            case "reactivate":
                return 1;
            case "suspend":
                return 4;
            case "deactivate":
                return 3;
            case "changed":
                return 13;
            default:
                return 0;
        }
    }
    function handleToggle(index, boxData, close) {
        if (close === void 0) { close = false; }
        var newServiceList = __spreadArray([], boxesData, true);
        var groupID = boxData.service.group_id;
        var previousProduct = newServiceList.filter(function (service) { return service.toggle === true && groupID === service.service.group_id; });
        var activeServices = activeServicesList;
        var activeServicesId = activeServicesList === null || activeServicesList === void 0 ? void 0 : activeServicesList.map(function (item) { return item.content_supplier_product_id; });
        var activeGroups = [];
        newServiceList.map(function (item) {
            if (activeServicesId === null || activeServicesId === void 0 ? void 0 : activeServicesId.includes(item.service.id)) {
                activeGroups.push(item.service.group_id);
            }
        });
        var action = "";
        var selectedService = newServiceList[index];
        if (close) {
            if (activeGroups.includes(groupID) && !(activeServicesId === null || activeServicesId === void 0 ? void 0 : activeServicesId.includes(boxData.service.id))) {
                newServiceList.map(function (item, key) {
                    if (activeServicesId === null || activeServicesId === void 0 ? void 0 : activeServicesId.includes(item.service.id)) {
                        index = key;
                        boxData = item;
                    }
                });
            }
        }
        newServiceList.forEach(function (data) {
            if (data.toggle && groupID === data.service.group_id) {
                data.toggle = false;
                data.newStatus = 0;
                data.selectedAction = "select";
            }
        });
        selectedService.toggle = true;
        var oldServices = newServiceList.find(function (service) { return service.service.group_id === boxData.service.group_id && (activeServicesId === null || activeServicesId === void 0 ? void 0 : activeServicesId.includes(service.service.id)); });
        if (activeGroups.includes(groupID)) {
            if (!(activeServicesId === null || activeServicesId === void 0 ? void 0 : activeServicesId.includes(boxData.service.id))) {
                action = "changed";
                boxData.oldService = oldServices === null || oldServices === void 0 ? void 0 : oldServices.service;
            }
            else {
                action = boxData.currentStatus;
            }
        }
        else {
            action = boxData.currentStatus ? boxData.currentStatus : "activate";
        }
        if (previousProduct.length !== 0 && selectedService.service.id === previousProduct[0].service.id) {
            selectedService.toggle = false;
            selectedService.selectedAction = "select";
            selectedService.newStatus = 0;
            action = 'select';
            if (!(activeServicesId === null || activeServicesId === void 0 ? void 0 : activeServicesId.includes(selectedService.service.id)) && activeGroups.includes(groupID)) {
                newServiceList.forEach(function (data) {
                    if ((activeServicesId === null || activeServicesId === void 0 ? void 0 : activeServicesId.includes(data.service.id)) && data.service.group_id === groupID) {
                        data.toggle = true;
                        data.selectedAction = data.currentStatus ? data.currentStatus : 'activate';
                        action = data.selectedAction;
                        data.newStatus = data.service.status_id;
                    }
                    if (data.service.id === selectedService.service.id) {
                        data.newStatus = 0;
                        action = "activate";
                        data.selectedAction = action;
                    }
                });
            }
        }
        boxData.selectedAction = action;
        previousProduct.length !== 0 && creatingTheServiceBox(selectedService.service, activeServices, previousProduct[0].service.tier, boxData.boxToggle);
        handleServiceStatus(action, index, boxData);
        setBoxesData(newServiceList);
    }
    function upgradeAndDowngradeServices(product, tier) {
        var is_active = activeServicesList === null || activeServicesList === void 0 ? void 0 : activeServicesList.find(function (service) {
            return service.content_supplier_product_id === product.id;
        });
        if (tier !== 0) {
            if (tier === product.tier) {
                return is_active !== undefined ? "Manter Plano Atual" : "Indisponível";
            }
            else if (tier > product.tier) {
                return is_active !== undefined ? "Manter Plano Atual" : "Voltar ao Plano Básico";
            }
            else {
                return is_active !== undefined ? "Manter Plano Atual" : "Fazer Upgrade";
            }
        }
        return "Adicionar Serviço";
    }
    function divUpgradeMessage(product, boxToggle, tier) {
        var is_active = activeServicesList === null || activeServicesList === void 0 ? void 0 : activeServicesList.find(function (service) {
            return service.content_supplier_product_id === product.id;
        });
        if (product.tier === tier && is_active === undefined) {
            if (!boxToggle) {
                return (_jsxs("div", __assign({ className: "boxToggleDisabled" }, { children: [_jsx(InfoIcon, { style: { fontSize: "0.9rem", marginRight: "0.3rem", marginLeft: "0.3rem" } }), "N\u00E3o \u00E9 poss\u00EDvel ativar este servi\u00E7o."] })));
            }
        }
        if (product.tier === 2 && tier !== 0 && is_active === undefined) {
            if (!boxToggle) {
                return (_jsxs("div", __assign({ className: "boxToggleDisabled" }, { children: [_jsx(InfoIcon, { style: { fontSize: "0.9rem", marginRight: "0.3rem", marginLeft: "0.3rem" } }), "Para fazer Upgrade, o assinante precisa ter um servi\u00E7o ativo."] })));
            }
            return (_jsxs("div", __assign({ className: "box-floating-upgrade" }, { children: [_jsx(UpgradeIcon, {}), "Fa\u00E7a seu Upgrade agora!"] })));
        }
        if (product.tier === 1 && tier !== 0 && is_active === undefined) {
            if (!boxToggle) {
                return (_jsxs("div", __assign({ className: "boxToggleDisabled" }, { children: [_jsx(InfoIcon, { style: { fontSize: "0.9rem", marginRight: "0.3rem", marginLeft: "0.3rem" } }), "Para fazer Downgrade, o assinante precisa ter um servi\u00E7o ativo."] })));
            }
            return (_jsxs("div", __assign({ className: "box-floating-upgrade" }, { children: [_jsx(VerticalAlignBottomIcon, {}), "Fa\u00E7a seu Downgrade agora!"] })));
        }
    }
    function handleServiceStatus(event, index, boxData) {
        var _a;
        boxData.selectedAction = typeof event === "string" ? event : event.target.value;
        boxData.newStatus = getStatus(boxData.selectedAction);
        var promotion = boxData.service.promotion;
        var newServicesAction = __spreadArray([], servicesAction, true);
        var exists = false;
        newServicesAction.map(function (item, index) {
            if (item.content_supplier_product_id === boxData.service.id && (boxData.newStatus === boxData.service.status_id || boxData.newStatus === 0)) {
                newServicesAction.splice(index, 1);
                exists = true;
            }
            else if (item.content_supplier_product_id === boxData.service.id) {
                item.action = boxData.selectedAction;
                newServicesAction[index] = item;
                exists = true;
            }
            else if (item.oldServiceId && item.oldServiceId === boxData.service.id) {
                newServicesAction.splice(index, 1);
                exists = true;
            }
            else if (item.groupId === boxData.service.group_id && item.content_supplier_product_id !== boxData.service.id) {
                newServicesAction.splice(index, 1);
            }
        });
        if (!exists) {
            // if (!exists && promotion == 0) {
            newServicesAction.push({
                action: boxData.selectedAction,
                content_supplier_product_id: boxData.service.id,
                service: boxData.service.name,
                oldServiceId: (_a = boxData.oldService) === null || _a === void 0 ? void 0 : _a.id,
                groupId: boxData.service.group_id
            });
        }
        /*else if (!exists && promotion == 1 && (boxData.currentStatus == "suspend")) {
        
                    let activeProduct = groupLists?.[boxData.service.group_id]?.find(product => product.active === 1);
                    if (activeProduct) {
                        newServicesAction.push({
                            action: boxData.selectedAction,
                            content_supplier_product_id: activeProduct.id,
                            service: activeProduct.name,
                            oldServiceId: boxData.oldService?.id,
                            groupId: boxData.service.group_id,
                        });
                            }
                }*/
        setServicesAction(newServicesAction);
        var newBoxesData = __spreadArray([], boxesData, true);
        newBoxesData[index] = boxData;
        setBoxesData(__spreadArray([], newBoxesData, true));
    }
    function changeTab(tab) {
        props.callbackBoxesData(boxesData);
        props.callbackServicesAction(servicesAction);
        props.callbackNavigate(tab);
    }
    function canShowBoxData(service) {
        if (service.promotion === 0) {
            if (service.status_id !== 0) {
                return true;
            }
            var promotion = boxesData === null || boxesData === void 0 ? void 0 : boxesData.filter(function (item) {
                var current = item.service;
                if (service.group_id === current.group_id && service.tier === current.tier && current.promotion === 1 && current.id !== service.id) {
                    return item;
                }
            });
            return (promotion === null || promotion === void 0 ? void 0 : promotion.length) === 0;
        }
        else {
            var promotion = boxesData === null || boxesData === void 0 ? void 0 : boxesData.filter(function (item) {
                var current = item.service;
                if (service.group_id === current.group_id && service.tier === current.tier && current.promotion === 0 && current.id !== service.id) {
                    return item;
                }
            });
            return promotion[0].service.status_id === 0;
        }
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "alert d-flex align-content-center service-alert-message", role: "alert" }, { children: [_jsx(InfoIcon, { className: "align-self-center", style: { width: "16px", height: "16px", marginRight: "0.3rem" } }), _jsx("p", __assign({ className: "align-self-center" }, { children: "Selecione os servi\u00E7os que desejar e defina o status de cada token." }))] })), _jsx("div", __assign({ className: "container-services-grid" }, { children: boxesData.map(function (boxData, index) {
                    var getStatusInfo = TokenStatusInformation(boxData.service.status_id);
                    if (canShowBoxData(boxData.service)) {
                        return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "service-box mt-4", style: !boxData.boxToggle ? { pointerEvents: "none" } : { cursor: "pointer" } }, { children: [(!boxData.toggle && activeServicesList !== undefined && activeServicesList.length > 0) && boxData.divElement, _jsxs("div", __assign({ className: "card card-smooth service-box-header text-center \n\t\t\t\t\t\t\t\t\t\t".concat(boxData.toggle ? "opened" : "closed") }, (!boxData.toggle && { onClick: function () { return handleToggle(index, boxData); }, }), { children: [_jsx("div", __assign({ className: "service-box-header-image d-flex justify-content-end ".concat(boxData.toggle ? null : "gray"), style: { backgroundImage: "url(".concat(boxData.service.img_path, ")") } }, { children: boxData.toggle && !boxData.provisioned && (_jsx(HighlightOffIcon, { className: "icon-size", style: { cursor: "pointer", color: "#fff" }, onClick: function () { return handleToggle(index, boxData, true); } })) })), _jsx("div", __assign({ className: "d-flex justify-content-center add-service" }, { children: _jsx("h4", __assign({ className: "align-self-center" }, { children: boxData.titleService })) }))] })), boxData.toggle && (_jsx("div", __assign({ className: "card card-smooth service-box-content", style: { paddingTop: "15px" } }, { children: _jsx("div", __assign({ className: "d-flex" }, { children: _jsx("div", __assign({ className: "flex-grow-1" }, { children: _jsx("div", __assign({ className: "d-flex gap-2 align-items-center" }, { children: boxData.provisioned ? (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "d-flex flex-column" }, { children: _jsxs("div", __assign({ className: "d-flex gap-2 pt-2" }, { children: [activeServicesList && (activeServicesList === null || activeServicesList === void 0 ? void 0 : activeServicesList.map(function (serviceError) { return (serviceError.has_error !== 0 && serviceError.content_supplier_product_id === boxData.service.id &&
                                                                        _jsxs("div", __assign({ className: "container-service-message-error" }, { children: [_jsx("div", { children: _jsx(ReportProblemIcon, { className: "icon-message-error", sx: { fontSize: "8rem", color: "#fa0234" } }) }), _jsxs("div", __assign({ className: "message-error" }, { children: [_jsx("h4", { children: "ERRO" }), _jsxs("p", { children: ["\u00DAltima tentativa: ", _jsx("span", { children: getStatusInfo.statusInformation.title.toUpperCase() })] }), _jsxs("p", __assign({ className: "text-message-error" }, { children: ["Algo deu errado na \u00FAltima a\u00E7\u00E3o. Tente novamente atrav\u00E9s da tabela de ", _jsx(Link, __assign({ to: "/subscribers?document=".concat(subscriberInfo.document), className: "link-to-subscriber-error" }, { children: "Gerenciar Assinantes" })), " ou entre em contato com o nosso suporte."] }))] }))] }))); })), activeServicesList && (activeServicesList === null || activeServicesList === void 0 ? void 0 : activeServicesList.map(function (serviceError) { return (serviceError.has_error === 0 && serviceError.content_supplier_product_id === boxData.service.id &&
                                                                        _jsxs("div", __assign({ className: "d-flex flex-column px-3" }, { children: [_jsxs("div", __assign({ className: "d-flex gap-2 pt-2" }, { children: [_jsx("h4", __assign({ style: { fontWeight: "600", marginLeft: "0.4rem", fontSize: "1.5rem" } }, { children: "Alterar status" })), _jsxs("div", __assign({ className: "box-select-status-service" }, { children: [boxData.selectedAction === "activate" || boxData.selectedAction === "reactivate" ? (_jsx(CheckCircleIcon, { style: { width: "1.5rem" }, sx: { color: "#EA5A1C" } })) : boxData.selectedAction === "suspend" ? (_jsx(PauseCircleIcon, { style: { width: "1.5rem" }, sx: { color: "#AEA09A" } })) : boxData.selectedAction === "deactivate" ? (_jsx(CancelOutlinedIcon, { style: { width: "1.5rem" }, sx: { color: "#EA5A1C" } })) : _jsx(CheckCircleIcon, { style: { width: "1.5rem" }, sx: { color: "#EA5A1C" } }), _jsxs("select", __assign({ className: "service-status", value: boxData.selectedAction, onChange: function (event) { return handleServiceStatus(event, index, boxData); } }, { children: [_jsx("option", { value: "select", disabled: true, hidden: true }), boxData.options] }))] }))] })), boxData.selectedAction !== boxData.currentStatus &&
                                                                                    boxData.selectedAction !== "" &&
                                                                                    _jsx("div", __assign({ id: "box-alert-service-status" }, { children: _jsxs("span", { children: [_jsx(InfoIcon, { style: {
                                                                                                        width: "16px",
                                                                                                        height: "16px",
                                                                                                        marginRight: "0.3rem",
                                                                                                        marginBottom: "0.2rem"
                                                                                                    } }), "Ao alterar o status do servi\u00E7o, voc\u00EA far\u00E1 altera\u00E7\u00F5es diretas no provisionamento e na cobran\u00E7a mensal do seu assinante."] }) })), _jsx("div", { children: _jsx("p", __assign({ className: "description-service" }, { children: boxData.service.description })) })] }))); }))] })) })) })) :
                                                        _jsxs("div", __assign({ className: "px-3 pt-3" }, { children: [_jsx("h4", __assign({ style: { fontWeight: "600", marginLeft: "0.4rem", marginBottom: "0.8rem" } }, { children: "Descri\u00E7\u00E3o" })), _jsx("p", __assign({ className: "description-service" }, { children: boxData.service.description }))] })) })) })) })) })))] }), boxData.service.id) }));
                    }
                }) })), _jsxs("div", __assign({ className: "d-flex justify-content-center", style: { gap: "33px", marginTop: "89px" } }, { children: [_jsx("button", __assign({ type: "button", className: "button-to-advance", onClick: function () { return changeTab("confirmForm"); } }, { children: "Avan\u00E7ar" })), _jsx("button", __assign({ type: "button", className: "button-to-cancel-action", onClick: function () { return changeTab("updateSubscriber"); } }, { children: "Voltar" }))] }))] }));
};
export default ServicesForm;
