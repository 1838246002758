var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import "../../App/styles/Paginate.css";
var Paginate = function (props) {
    props.paginateData.links[0].label = "<";
    props.paginateData.links[props.paginateData.links.length - 1].label = ">";
    return (_jsx("div", __assign({ className: "" }, { children: _jsx("nav", __assign({ "aria-label": "Page navigation example" }, { children: _jsx("ul", __assign({ className: "pagination" }, { children: props.paginateData.links.map(function (link, key) {
                    if (link.url === null) {
                        return (_jsx("li", __assign({ className: "page-item" }, { children: _jsx("button", __assign({ className: "page-link paginate", disabled: true }, { children: _jsx("span", { dangerouslySetInnerHTML: { __html: link.label } }) })) }), key));
                    }
                    return link.active ? (_jsx("li", __assign({ className: "page-item active" }, { children: _jsx("button", __assign({ className: "page-link paginate", onClick: function () { return props.callback(link.url); } }, { children: _jsx("span", { dangerouslySetInnerHTML: { __html: link.label } }) })) }), key)) : (_jsx("li", __assign({ className: "page-item linkRow" }, { children: _jsx("button", __assign({ className: "page-link paginate", onClick: function () { return props.callback(link.url); } }, { children: _jsx("span", { dangerouslySetInnerHTML: { __html: link.label } }) })) }), key));
                }) })) })) })));
};
export default Paginate;
