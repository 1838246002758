export var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export var PHONE_REGEX = /^\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*$/;
export var DOCUMENT_REGEX = /(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/;
export var documentFormatter = function (document) {
    document = document.replace(/\D/g, "");
    if (document.length <= 11) {
        document = document.replace(/(\d{3})(\d)/, "$1.$2");
        document = document.replace(/(\d{3})(\d)/, "$1.$2");
        document = document.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    }
    else {
        document = document.replace(/^(\d{2})(\d)/, "$1.$2");
        document = document.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        document = document.replace(/\.(\d{3})(\d)/, ".$1/$2");
        document = document.replace(/(\d{4})(\d)/, "$1-$2");
    }
    return document;
};
export var phoneNumberFormatter = function (phoneNumber) {
    phoneNumber = phoneNumber.replace(/\D/g, "");
    phoneNumber = phoneNumber.replace(/(\d{2})(\d)/, '($1) $2');
    phoneNumber = phoneNumber.replace(/(\d{5})(\d)/, '$1-$2');
    phoneNumber = phoneNumber.replace(/(-\d{4})\d+?$/, '$1');
    return phoneNumber;
};
export var phoneNumberFormatter2 = function (phoneNumber) {
    var formattedNumber;
    if (phoneNumber.startsWith("0800")) {
        phoneNumber.replace(/\D/g, "");
        formattedNumber = "0800 ".concat(phoneNumber.slice(4, 6), " ").concat(phoneNumber.slice(6));
    }
    else if (phoneNumber.startsWith("55") && phoneNumber.length === 13) {
        var countryCode = phoneNumber.substring(2, 4);
        var areaCode = phoneNumber.substring(4, 6);
        var firstPart = phoneNumber.substring(6, 10);
        var secondPart = phoneNumber.substring(10);
        formattedNumber = "+55 (".concat(areaCode, ") ").concat(firstPart, "-").concat(secondPart);
    }
    else if (phoneNumber.length === 11) {
        var areaCode = phoneNumber.substring(0, 2);
        var firstPart = phoneNumber.substring(2, 6);
        var secondPart = phoneNumber.substring(6);
        formattedNumber = "(".concat(areaCode, ") ").concat(firstPart, "-").concat(secondPart);
    }
    else if (phoneNumber.length === 13) {
        var countryCode = phoneNumber.substring(0, 2);
        var areaCode = phoneNumber.substring(2, 4);
        var firstPart = phoneNumber.substring(4, 8);
        var secondPart = phoneNumber.substring(8);
        formattedNumber = "+".concat(countryCode, " (").concat(areaCode, ") ").concat(firstPart, "-").concat(secondPart);
    }
    else {
        formattedNumber = phoneNumber;
    }
    return formattedNumber;
};
export var dateFormatter = function (date) {
    return new Date(date).toLocaleDateString();
};
export var removeCharactersAndSpecialCharacter = function (value) {
    return value.replace(/[^0-9]/g, "");
};
export var formatCurrencyValues = function (total) {
    if (total > 0) {
        return total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }
    else {
        var negative = total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        return "(" + negative.substring(1) + ")";
    }
};
