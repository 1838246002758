var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Modal from 'react-bootstrap/Modal';
import "./../styles/ModalToDisapproveOrApproveArt.css";
import "../../App/styles/GlobalStyle.css";
import ModalReviewOfTheArt from "./ModalReviewOfArt";
import ArtsService from "../services/ArtsService";
import Notification from "../../App/components/Notification";
function ModalToDisapproveOrApproveArt(props) {
    var _a;
    function sendEmailApproved(artsData) {
        ArtsService.sendEmailApproved(artsData)
            .then(function (res) {
            Notification().Block.circle("[data-id=arts-grid]");
            Notification().Notify.success("E-mail de aprovação enviada com sucesso!");
            props.closeModal();
            props.updateArts().then(function () {
                Notification().Block.remove("[data-id=arts-grid]");
            });
        }, function (err) {
            Notification().Block.remove("[data-id=arts-grid]");
            Notification().Notify.failure(err.response.data.response.error);
        });
    }
    var handleSendEmailApprovedArt = function (user_id, art_id) {
        Notification().Confirm.show("Aprovar arte", "Tem certeza que deseja aprovar a arte?", "Aprovar", "Cancelar", function () {
            sendEmailApproved({ user_id: user_id, art_id: art_id });
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, __assign({ show: props.showModal, size: "lg", "aria-labelledby": "contained-modal-title-vcenter", centered: true }, props, { className: "assessment-modal" }, { children: [_jsx(Modal.Header, { children: _jsxs(Modal.Title, __assign({ id: "contained-modal-title-vcenter" }, { children: ["Avaliar arte de ", " ", _jsx("strong", { children: (_a = props.art) === null || _a === void 0 ? void 0 : _a.user_uploaded_art })] })) }), _jsxs(Modal.Body, { children: [_jsx("div", { children: _jsx("p", { children: "Ao avaliar certifique-se que visualizou corretamente o arquivo relacionado ao ISP desejado. Ap\u00F3s a confirma\u00E7\u00E3o o ISP receber\u00E1 por e-mail a sua resposta" }) }), _jsxs("div", __assign({ className: "d-flex justify-content-center gap-3" }, { children: [_jsx("button", __assign({ className: "button-to-cancel-action", style: { width: "9rem", height: "36px" }, onClick: props.closeModal }, { children: "Cancelar" })), _jsx("button", __assign({ className: "button-to-advance", style: { width: "9rem", height: "36px" }, onClick: function () { props.callbackHandleArtClick(props.art); } }, { children: "Reprovar" })), _jsx("button", __assign({ className: "button-to-assess", style: { width: "9rem", height: "36px" }, onClick: function () { handleSendEmailApprovedArt(props.art.user_id, props.art.id); } }, { children: "Aprovar" }))] }))] })] })), _jsx(ModalReviewOfTheArt, { show: props.showModalReview, artToReview: props.art, updateArts: props.updateArts, closeModal: props.closeModalReview })] }));
}
export default ModalToDisapproveOrApproveArt;
