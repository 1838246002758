var stepsAdminHome = [
    {
        selector: '.start',
        content: 'Seja bem-vindo! Vamos aprender como nossa plataforma funciona!'
    },
    {
        selector: '.admin-header-providers',
        content: 'Clique aqui para ter acesso ao cadastro ou ao gerenciamento de provedores!'
    },
    {
        selector: '.admin-header-services',
        content: 'Clique aqui para gerenciar todos os Content Suppliers.'
    },
    {
        selector: '.admin-header-reports-providers',
        content: 'Clique aqui para ter acesso ao relatório de provedores.'
    },
    {
        selector: '.header-logout',
        content: 'Clique aqui para sair da plataforma!'
    },
    {
        selector: '.admin-home',
        content: 'Aqui você pode ver as informações do painel do administrador!'
    },
    {
        selector: '.footer-links',
        content: 'Aqui você tem acesso a algumas informações importantes!'
    },
    {
        selector: '.footer-whatsapp',
        content: 'Clique aqui para enviar uma mensagem!'
    }
];
var stepsAdminProvidersNew = [
    {
        selector: '.admin-providerNew',
        content: 'Aqui você pode buscar os dados de um provedor no Bling! Se o provedor não for encontrado você será redirecionado para o formulário de cadastro.'
    }
];
var stepsAdminProviders = [
    {
        selector: '.admin-providers',
        content: 'Aqui você pode visualizar todos os provedores cadastrados!'
    },
    {
        selector: '.admin-providers-CS',
        content: 'Aqui você pode alterar o sucesso do cliente responsável pelo provedor!'
    },
    {
        selector: '.admin-providers-impersonate',
        content: 'Clique aqui para personificar o provedor!'
    }
];
var stepsAdminProvidersCS = [
    {
        selector: '.admin-providersCS',
        content: 'Aqui você pode atribuir ou alterar um sucesso do cliente!'
    }
];
var stepsAdminContents = [
    {
        selector: '.admin-contents',
        content: 'Aqui você pode visualizar todos os content suppliers cadastrados!'
    },
    {
        selector: '.admin-contents-actions',
        content: 'Clique aqui para gerenciar os produtos do content supplier!'
    }
];
var stepsAdminContentsProducts = [
    {
        selector: '.admin-contentsProducts',
        content: 'Aqui você pode visualizar todos os produtos do content supplier!'
    },
    {
        selector: '.admin-contentsProducts-value',
        content: 'Clique aqui designar um valor para o produto!'
    }
];
var stepsAdminReportsProviders = [
    {
        selector: '.admin-reportsProviders',
        content: 'Aqui você pode visualizar o relatório de provedores!'
    },
    {
        selector: '.admin-reportsProviders-export',
        content: 'Clique aqui para exportar o relatório!'
    },
    {
        selector: '.admin-reportsProviders-filters',
        content: 'Selecione os filtros que deseja aplicar!'
    }
];
var stepsCSHome = [
    {
        selector: '.start',
        content: 'Seja bem-vindo! Vamos aprender como nossa plataforma funciona!'
    },
    {
        selector: '.cs-header-providers',
        content: 'Clique aqui para ter acesso ao cadastro ou ao gerenciamento de provedores!'
    },
    {
        selector: '.cs-header-services',
        content: 'Clique aqui para gerenciar todos os Content Suppliers.'
    },
    {
        selector: '.cs-header-reports-providers',
        content: 'Clique aqui para ter acesso ao relatório de provedores.'
    },
    {
        selector: '.header-logout',
        content: 'Clique aqui para sair da plataforma!'
    },
    {
        selector: '.cs-home',
        content: 'Aqui você pode ver as informações do painel do cs!'
    },
    {
        selector: '.footer-links',
        content: 'Aqui você tem acesso a algumas informações importantes!'
    },
    {
        selector: '.footer-whatsapp',
        content: 'Clique aqui para enviar uma mensagem!'
    }
];
var stepsProvidersHome = [
    {
        selector: '.start',
        content: 'Seja bem-vindo! Vamos começar o tour pela nossa página inicial!'
    },
    {
        selector: '.provider-header-subscribers',
        content: 'Clique aqui para ter acesso ao cadastro, gerenciamento ou importação de assinantes!'
    },
    {
        selector: '.provider-header-billings',
        content: 'Clique aqui para visualizar suas faturas!'
    },
    {
        selector: '.provider-header-me',
        content: 'Clique aqui para visualizar seus dados!'
    },
    {
        selector: '.header-logout',
        content: 'Clique aqui para sair da plataforma!'
    },
    {
        selector: '.provider-home-statistics',
        content: 'Aqui você pode ver as estatísticas de assinantes por produtos!'
    },
    {
        selector: '.provider-home-subscribers-activation',
        content: 'Aqui você pode ver os assinantes por ativação!'
    },
    {
        selector: '.provider-home-current-profit-margin',
        content: 'Aqui você pode ver a margem de lucro atual!'
    },
    {
        selector: '.provider-home-whatsapp',
        content: 'Clique aqui para enviar uma mensagem para o seu Sucesso do Cliente!'
    },
    {
        selector: '.footer-links',
        content: 'Aqui você tem acesso a algumas informações importantes!'
    },
];
var stepsProviderSubscribers = [
    {
        selector: '.provider-subscribers',
        content: 'Aqui você pode visualizar todos os seus assinantes!'
    },
    {
        selector: '.provider-subscribers-action',
        content: 'Clique aqui para ter acesso as ações de ativação, exclusão e edição de dados!'
    },
    {
        selector: '.provider-subscribers-buttons',
        content: 'Aqui você tem acesso a algumas ações'
    },
    {
        selector: '.provider-subscribers-search',
        content: 'Clique aqui para buscar um assinante! (pode pesquisar por nome, e-mail, documento e telefone)'
    },
    {
        selector: '.provider-subscribers-actions',
        content: 'Clique aqui para realizar ações em massa!'
    }
];
var stepsProviderSubscribersNew = [
    {
        selector: '.provider-subscribersNew',
        content: 'Aqui você pode preencher as informações de cadastro do assinante'
    },
    {
        selector: '.provider-subscribersNew-services',
        content: 'Aqui você pode escolher qual serviço deseja e fazer upgrades!'
    },
    {
        selector: '.provider-subscribersNew-services-confirm',
        content: 'Aqui você pode revisar os dados do cadastro e status do token antes de confirmar!'
    },
];
var stepsProviderSubscribersImport = [
    {
        selector: '.provider-subscribersImport',
        content: 'Aqui você pode importar assinantes fazendo upload de um arquivo .csv!'
    },
    {
        selector: '.provider-subscribersImport-list',
        content: 'Lembre-se de usar o código relacionado ao serviço!'
    },
    {
        selector: '.provider-subscribersImport-example',
        content: 'Você pode baixar um modelo de exemplo!'
    }
];
var stepsProviderBillings = [
    {
        selector: '.provider-billings',
        content: 'Aqui você pode visualizar suas faturas!'
    },
    {
        selector: '.provider-billings-details',
        content: 'Clique aqui para visualizar detalhes!'
    }
];
var stepsProviderBillingsDetails = [
    {
        selector: '.provider-billings-details-total',
        content: 'Aqui você pode visualizar o total da fatura!'
    },
    {
        selector: '.provider-billings-details-graphic',
        content: 'Aqui você pode visualizar o gráfico onde mostra quantidade de assinantes em cada serviço',
    },
    {
        selector: '.provider-billings-details-table',
        content: 'Aqui você pode visualizar a tabela com as informações dos assinantes e o status do pagamento!'
    }
];
var stepsProviderMe = [
    {
        selector: '.provider-me-update',
        content: 'Clique aqui para você pode alterar os seus dados'
    },
    {
        selector: '.provider-me',
        content: 'Aqui você pode visualizar seus dados pessoais!'
    },
    {
        selector: '.provider-me-contact',
        content: 'Aqui você pode visualizar os dados de contato do seu sucesso do cliente, closer e vendedor!'
    },
];
function tourSteps(path, role) {
    var steps = [];
    var pathnameBillingsDetail = window.location.pathname.match(/^\/billings\/(\d+)/g);
    if (pathnameBillingsDetail !== null) {
        path = '/billings_details';
    }
    switch (path) {
        case '/':
            if (role === 1) {
                steps = stepsAdminHome;
            }
            else if (role === 2) {
                steps = stepsCSHome;
            }
            else if (role === 3) {
                steps = stepsProvidersHome;
            }
            break;
        case '/providers':
            steps = stepsAdminProviders;
            break;
        case '/providers/new':
            steps = stepsAdminProvidersNew;
            break;
        case '/contents':
            steps = stepsAdminContents;
            break;
        case '/contents/products':
            steps = stepsAdminContentsProducts;
            break;
        case '/reports/providers':
            steps = stepsAdminReportsProviders;
            break;
        case '/subscribers':
            steps = stepsProviderSubscribers;
            break;
        case '/subscribers/new':
            steps = stepsProviderSubscribersNew;
            break;
        case '/subscribers/import':
            steps = stepsProviderSubscribersImport;
            break;
        case '/billings':
            steps = stepsProviderBillings;
            break;
        case '/billings_details':
            steps = stepsProviderBillingsDetails;
            break;
        case '/profile':
            steps = stepsProviderMe;
            break;
    }
    return steps;
}
export default { tourSteps: tourSteps };
