var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import notFound from "../../App/assets/not-found.png";
import "../styles/not-found.css";
function NotFound() {
    var navigate = useNavigate();
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "container-not-found" }, { children: [_jsx("img", { className: "img-not-found", src: notFound, alt: "p\u00E1gina de erro" }), _jsx("h2", __assign({ className: "title-error" }, { children: "Oops!" })), _jsx("p", { children: "Algo deu errado. N\u00E3o conseguimos encontrar o que estava procurando." }), _jsx("button", __assign({ className: "btn-super btn-back", onClick: function () { return navigate(-1); } }, { children: "Voltar" }))] })) }));
}
export default NotFound;
