import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { activeStatusId, canceledStatusId, checkoutStatusId, suspendStatusId, limitReachedStatusId, inProgressStatusId, reactivatedStatusId, changedStatusId, waitingSuspension, waitingCancellation } from "./TokenStatusHelper";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from '@mui/icons-material/Block';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TimerIcon from '@mui/icons-material/Timer';
import UpdateIcon from '@mui/icons-material/Update';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import ScheduleIcon from '@mui/icons-material/Schedule';
import "../../App/styles/GlobalStyle.css";
export function TokenStatusInformation(statusId) {
    var statusInformation = {
        icon: _jsx(_Fragment, {}),
        iconClassName: '',
        title: '',
        long_message: '',
        short_message: ''
    };
    switch (statusId) {
        case activeStatusId:
        case checkoutStatusId:
        case inProgressStatusId:
            statusInformation = {
                icon: _jsx(CheckCircleIcon, {}),
                iconClassName: 'service-activated-icon clickable-icon',
                title: 'Serviço habilitado',
                long_message: 'Serviço habilitado com sucesso!',
                short_message: 'Serviço habilitado'
            };
            break;
        case changedStatusId:
            statusInformation = {
                icon: _jsx(UpdateIcon, {}),
                iconClassName: 'exchange-of-services-icon clickable-icon',
                title: 'Troca de Serviços',
                long_message: 'Aguardando Processamento de Troca de Serviços',
                short_message: 'Processamento de Troca de Serviços.'
            };
            break;
        case reactivatedStatusId:
            statusInformation = {
                icon: _jsx(HistoryToggleOffIcon, {}),
                iconClassName: 'service-reactivated-icon clickable-icon',
                title: 'Aguardando reativação',
                long_message: 'Aguardando reativação do serviço',
                short_message: 'Aguardando reativação'
            };
            break;
        case canceledStatusId:
            statusInformation = {
                icon: _jsx(BlockIcon, {}),
                iconClassName: 'blocked-service clickable-icon',
                title: 'Cancelado',
                long_message: 'Este usuário está cancelado',
                short_message: 'Usuário cancelado'
            };
            break;
        case suspendStatusId:
            statusInformation = {
                icon: _jsx(PauseCircleFilledIcon, {}),
                iconClassName: 'pause-circle-filled-icon clickable-icon',
                title: 'Suspenso',
                long_message: 'Este usuário está suspenso',
                short_message: 'Usuário suspenso'
            };
            break;
        case limitReachedStatusId:
            statusInformation = {
                icon: _jsx(ReportProblemIcon, {}),
                iconClassName: 'report-problem-icon clickable-icon',
                title: 'Limite de assinantes atingido',
                long_message: 'Assinante não provisionado. Entre em contato com seu CS para aumentar seu limite',
                short_message: 'Assinante não provisionado'
            };
            break;
        case waitingSuspension:
            statusInformation = {
                icon: _jsx(TimerIcon, {}),
                iconClassName: 'service-waiting-suspension-icon clickable-icon',
                title: 'Aguardando suspensão',
                long_message: 'Aguardando suspensão',
                short_message: 'Aguardando suspensão'
            };
            break;
        case waitingCancellation:
            statusInformation = {
                icon: _jsx(ScheduleIcon, {}),
                iconClassName: 'service-waiting-cancellation-icon clickable-icon',
                title: 'Aguardando cancelamento',
                long_message: 'Aguardando cancelamento',
                short_message: 'Aguardando cancelamento'
            };
            break;
        default:
            statusInformation = {
                icon: _jsx(BlockIcon, {}),
                iconClassName: 'blocked-service clickable-icon',
                title: 'Status não mapeado',
                long_message: 'Status não mapeado',
                short_message: 'Status não mapeado'
            };
            break;
    }
    ;
    return ({ statusInformation: statusInformation });
}
