var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../styles/MemberHandbookWelcome.css";
import "../../App/styles/GlobalStyle.css";
import backgroundMemberHandbook from "../../App/assets/background-member-handbook-welcome.png";
function MemberHandbookWelcome() {
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "container-member-handbook-welcome" }, { children: [_jsxs("div", __assign({ className: "manual-welcome-message-box" }, { children: [_jsx("h4", __assign({ className: "title-page-member-handbook-welcome" }, { children: "Seja bem vindo!" })), _jsxs("h1", { children: ["\u00C1rea do ", _jsx("br", {}), "Associado"] }), _jsxs("p", { children: ["Aqui voc\u00EA encontra as nossas solu\u00E7\u00F5es, acesso facilitado ao site Celeti ", _jsx("br", {}), "  HUB e nossos materiais informativos. Al\u00E9m de templates para divulga\u00E7\u00E3o ", _jsx("br", {}), " da parceria com os nossos principais fornecedores de SVAs, para ", _jsx("br", {}), " personalizar com seu logo e descri\u00E7\u00E3o de seus planos."] })] })), _jsx("img", { className: "background-member-handbook", src: backgroundMemberHandbook, alt: "Imagem de fundo" })] })) }));
}
export default MemberHandbookWelcome;
