var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "../../App/styles/Breadcrumbs.css";
function Breadcrumbs(_a) {
    var itens = _a.itens;
    return (_jsx(_Fragment, { children: _jsx("nav", __assign({ className: "box-breadcrumbs" }, { children: _jsxs("ul", { children: [_jsx("span", __assign({ className: "nav-text-breadcrumbs" }, { children: "Voc\u00EA est\u00E1 em:" })), itens.map(function (item, index) { return (_jsxs("li", { children: [_jsx("a", __assign({ href: item.route, className: "text-name-breadcrumbs" }, { children: item.name })), index !== itens.length - 1 && _jsx("span", __assign({ className: "breadcrumbs-arrow" }, { children: "> " }))] }, index)); })] }) })) }));
}
export default Breadcrumbs;
