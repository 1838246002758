var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../services/api";
import { unauthenticatedUser } from "../contexts/AuthProvider";
import useAuth from "../hooks/useAuth";
import cookies from "../services/cookies";
import CustomerService from "../../Customer/services/CustomerService";
import "../styles/BoxImpersonate.css";
function BoxImpersonate() {
    var unAuthenticate = useAuth().unAuthenticate;
    var _a = useAuth(), auth = _a.auth, setAuth = _a.setAuth;
    var navigate = useNavigate();
    var _b = useState(true), showElement = _b[0], setShowElement = _b[1];
    function unImpersonate() {
        return __awaiter(this, void 0, void 0, function () {
            var oldToken, data, date;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        oldToken = cookies.get("token_old");
                        return [4 /*yield*/, CustomerService.unImpersonateCustomer({ user_id: auth.id, token: oldToken })];
                    case 1:
                        data = (_a.sent()).data;
                        if (data.user === undefined) {
                            cookies.remove("token");
                            setAuth(unauthenticatedUser);
                        }
                        else {
                            api.defaults.headers.common["Authorization"] = "Bearer " + oldToken;
                            cookies.set("token", oldToken, "/");
                            date = new Date();
                            date.setTime(date.getTime() - 1);
                            cookies.set('token_old', '', { path: '/', expires: date });
                            setAuth({
                                signed: true,
                                id: data.user.id,
                                name: data.user.name,
                                role_id: data.user.role_id,
                                permissions: data.permissions,
                                impersonate: false,
                                has_unpaid_bills: data.customer.length !== 0 ? data.customer.has_unpaid_bills : 0
                            });
                        }
                        cookies.remove("token_old");
                        navigate("/");
                        return [2 /*return*/];
                }
            });
        });
    }
    function toggleBoxUserAccess(toggle) {
        setShowElement(toggle);
    }
    return (_jsx(_Fragment, { children: auth.impersonate && (_jsxs("div", __assign({ style: { position: "absolute" } }, { children: [_jsx("div", { className: "box-border-left" }), _jsx("div", { className: "box-border-right" }), _jsx("div", { className: "box-border-top" }), _jsx("div", { className: "box-border-bottom" }), !showElement ?
                    _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "button-maximum-user-access" }, { children: [_jsx("button", __assign({ onClick: function () { return toggleBoxUserAccess(true); } }, { children: _jsx("span", __assign({ className: "material-symbols-outlined", style: { fontSize: "1.6rem" } }, { children: "stat_minus_1" })) })), _jsx("button", __assign({ onClick: function () { return unImpersonate(); } }, { children: _jsx("span", __assign({ className: "material-symbols-outlined", style: { fontSize: "1.6rem" } }, { children: "close_small" })) }))] })) }) :
                    _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "button-minimize-user-access" }, { children: [_jsx("button", __assign({ onClick: function () { return toggleBoxUserAccess(false); } }, { children: _jsx("span", __assign({ className: "material-symbols-outlined", style: { fontSize: "1.6rem" } }, { children: "stat_1" })) })), _jsx("button", __assign({ onClick: function () { return unImpersonate(); } }, { children: _jsx("span", __assign({ className: "material-symbols-outlined", style: { fontSize: "1.6rem" } }, { children: "close_small" })) }))] })) }), showElement &&
                    _jsx("div", { children: _jsx("div", __assign({ className: "box-user-access", style: { top: "0" } }, { children: _jsxs("p", { children: ["Voc\u00EA est\u00E1 acessando o provedor ", _jsx("strong", { children: auth.name })] }) })) })] }))) }));
}
export default BoxImpersonate;
