var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import CustomerService from '../../Customer/services/CustomerService';
import cookies from '../services/cookies';
import { api } from '../services/api';
import { useNavigate } from 'react-router-dom';
import Notification from './Notification';
import useAuth from '../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import AttributionIcon from "@mui/icons-material/Attribution";
var Impersonate = function (_a) {
    var customerId = _a.customerId;
    var navigate = useNavigate();
    var setAuth = useAuth().setAuth;
    var location = useLocation();
    var handleImpersonate = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, adminToken, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, CustomerService.impersonate(customerId)];
                case 1:
                    res = _a.sent();
                    adminToken = cookies.get("token");
                    cookies.set("token_old", adminToken, "/");
                    api.defaults.headers.common["Authorization"] = "Bearer " + res.data.token;
                    cookies.set("token", res.data.token, "/");
                    setAuth({
                        signed: true,
                        id: res.data.user.id,
                        name: res.data.user.name,
                        role_id: res.data.user.role_id,
                        permissions: res.data.permissions,
                        impersonate: true,
                        has_unpaid_bills: res.data.customer.length !== 0 ? res.data.customer.has_unpaid_bills : 0
                    });
                    navigate("/");
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    if (err_1.response.status === 403) {
                        Notification().Notify.failure("Usuário não tem permissão para executar essa ação!");
                    }
                    else {
                        Notification().Notify.failure(err_1.message);
                    }
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (location.pathname === "/providers") {
        return (_jsx(_Fragment, { children: _jsxs(Dropdown.Item, __assign({ value: "Personificar", className: "item-action-dropdown", onClick: function () { return handleImpersonate(); } }, { children: [_jsx(AttributionIcon, {}), "Personificar"] })) }));
    }
    else {
        return (_jsx("button", __assign({ onClick: handleImpersonate, className: "button-impersonate", style: { width: "6rem", height: "2rem" } }, { children: "Personificar" })));
    }
};
export default Impersonate;
