import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import useAuth from "../hooks/useAuth";
export var Can = function (props) {
    var auth = useAuth().auth;
    return (auth.permissions.includes(props.permission) ?
        _jsx(_Fragment, { children: props.children }) :
        null);
};
export var CanAny = function (props) {
    var auth = useAuth().auth;
    return (auth.permissions.some(function (permission) {
        return props.permissions.includes(permission);
    }) ?
        _jsx(_Fragment, { children: props.children }) :
        null);
};
