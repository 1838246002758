var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "../styles/Support.css";
import bgSupport from "../../App/assets/bg-support.png";
import imgSupport from "../../App/assets/img-support.png";
import imgLogoCeletiHub from "../../App/assets/celeti_hub_logo_branco.png";
function Support() {
    var location = useLocation();
    var navigate = useNavigate();
    function reloadPage(url) {
        if (window.location.pathname === url) {
            navigate(url, { replace: true });
            window.location.reload();
        }
    }
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "container-support" }, { children: [_jsx("div", __assign({ className: "bg-support" }, { children: _jsx("img", { src: bgSupport, alt: "Imagem de fundo" }) })), _jsx("div", __assign({ className: "img-support" }, { children: _jsx("img", { src: imgSupport, alt: "Ilustra\u00E7\u00E3o" }) })), _jsxs("div", __assign({ className: "box-support" }, { children: [_jsx("div", __assign({ id: "box-support-logo-celetihub" }, { children: _jsx("img", { src: imgLogoCeletiHub, alt: "Logo CeletiHub" }) })), _jsxs("div", __assign({ className: "box-text-support" }, { children: [_jsx("h1", __assign({ id: "title-support" }, { children: "EST\u00C1 COM PROBLEMAS?" })), _jsx("p", __assign({ className: "text-support" }, { children: "Fique tranquilo. A CeletiHUB \u00E9 uma empresa parceira da Globoplay. Para tirar suas d\u00FAvidas, e resolver quaisquer problemas relacionados ao seu provisionamento, sinta-se \u00E0 vontade para usar nossos meios de comunica\u00E7\u00E3o." })), _jsxs("div", __assign({ className: "box-buttons-support" }, { children: [_jsx(Link, __assign({ to: "https://api.whatsapp.com/send?phone=552140201697", onClick: function () {
                                                return reloadPage("https://api.whatsapp.com/send?phone=552140201697");
                                            } }, { children: _jsx("button", __assign({ id: "btn-support" }, { children: "Entrar em contato" })) })), _jsx(Link, __assign({ to: "/suporte/faq", onClick: function () { return reloadPage("/suporte/faq"); } }, { children: _jsx("button", __assign({ id: "btn-support" }, { children: "FAQ" })) }))] }))] }))] }))] })) }));
}
export default Support;
