var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { TourProvider, useTour } from "@reactour/tour";
export var Tour = function (_a) {
    var children = _a.children;
    var steps = useTour().steps;
    function scroll(scroll) {
        scroll ? document.body.style.overflow = '' : document.body.style.overflow = 'hidden';
    }
    var styles = {
        popover: function (base) { return (__assign(__assign({}, base), { boxShadow: '0 0 3em rgba(0, 0, 0, 0.5)', background: '#dedede', borderRadius: '.25rem' })); },
        badge: function (base) { return (__assign(__assign({}, base), { background: '#003981' })); },
        close: function (base) { return (__assign(__assign({}, base), { position: 'absolute', right: 10, top: 10 })); },
        dot: function (base) { return (__assign(__assign({}, base), { background: '#003981' })); }
    };
    return (_jsx(TourProvider, __assign({ steps: steps, styles: styles, disableInteraction: true, onClickMask: function (e) { return e.setIsOpen(true); }, afterOpen: function () { return scroll(false); }, onClickClose: function (e) { scroll(true); e.setIsOpen(false); e.setCurrentStep(0); } }, { children: children })));
};
export default Tour;
