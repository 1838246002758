import { useState, useEffect } from "react";
import cookies from "../services/cookies";
function getStorageValue(key, defaultValue) {
    var value = sessionStorage.getItem(key);
    if (value && cookies.get('token')) {
        return JSON.parse(value);
    }
    else {
        sessionStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
    }
}
var useSessionStorage = function (key, defaultValue) {
    var _a = useState(function () {
        return getStorageValue(key, defaultValue);
    }), value = _a[0], setValue = _a[1];
    useEffect(function () {
        sessionStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
    return [value, setValue];
};
export default useSessionStorage;
